import { ChangeEvent } from 'react';
import { MechaNFTOrderType, MechaNFTOrder } from 'shared/interfaces';
import './MechaNFTSort.scss';

interface MechaNFTSortProps {
  // eslint-disable-next-line no-unused-vars
  setSort: (sort: MechaNFTOrderType) => void;
  sort: MechaNFTOrderType;
}

export default function MechaNFTSort(props: MechaNFTSortProps): JSX.Element {
  const { setSort, sort } = props;

  const onFilterSelectChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    const target: HTMLSelectElement = event.target as HTMLSelectElement;
    setSort(target.value as MechaNFTOrderType);
  };

  return (
    <div className="MechaNFTSort">
      <h2>Order By</h2>
      <select
        value={sort}
        onChange={(event) => {
          onFilterSelectChanged(event);
        }}
      >
        {Object.entries(MechaNFTOrder).map(([key, orderElement]) => (
          <option key={key} value={orderElement}>
            {orderElement}
          </option>
        ))}
      </select>
    </div>
  );
}
