import { useMetamask, useMyNFTs } from 'hooks';
import loadingImage from 'assets/images/1480.gif';

import './TestView.scss';
import { MechaNFTContractViewer, ThreeDiceNFTContractViewer } from 'shared/components';

const TestView = (): JSX.Element => {
  const { metaState } = useMetamask();
  const [currentAccount] = metaState.accounts;
  const { mechas, isLoadingMechas, threeDices, isLoadingThreeDices } = useMyNFTs(currentAccount);

  return (
    <div className="TestView p-5">
      <h1>Test View</h1>
      <h2>Account: {currentAccount}</h2>
      {(isLoadingMechas || isLoadingThreeDices) && (
        <div className="text-align-center">
          <img src={loadingImage} alt="loading" width={40} height={40} />
        </div>
      )}
      <div>
        {mechas.map((mecha) => (
          <div
            className="m-3"
            style={{ width: '250px', display: 'inline-block' }}
            key={mecha.tokenId}
          >
            <MechaNFTContractViewer contract={mecha} contractAddress="" />
          </div>
        ))}
      </div>
      {threeDices.map((threeDice) => (
        <div
          className="m-3"
          style={{ width: '250px', display: 'inline-block' }}
          key={threeDice.tokenId}
        >
          <ThreeDiceNFTContractViewer contract={threeDice} contractAddress="" />
        </div>
      ))}
    </div>
  );
};

export default TestView;
