import React, { createContext, ReactChild, useReducer } from 'react';
import { MechaNFTContract, ThreeDiceNFTContract } from 'shared/models';

export interface NFTsStoreState {
  mechas: Array<MechaNFTContract>;
  threeDices: Array<ThreeDiceNFTContract>;
}

type typeStateMapKeys = 'SET_MECHAS' | 'SET_TRHEEDICES';

// eslint-disable-next-line no-unused-vars
const typeStateMap: { [key in typeStateMapKeys]: string } = {
  SET_MECHAS: 'SET_MECHAS',
  SET_TRHEEDICES: 'SET_TRHEEDICES',
};

interface Action {
  type: typeStateMapKeys;
  payload: any;
}

const initialState: NFTsStoreState = {
  mechas: [],
  threeDices: [],
};

export function myNFTsReducer(state: NFTsStoreState, action: Action): NFTsStoreState {
  switch (action.type) {
    case typeStateMap.SET_MECHAS:
      return { ...state, mechas: action.payload };
    case typeStateMap.SET_TRHEEDICES:
      return { ...state, threeDices: action.payload };
    default:
      return state;
  }
}

const MyNFTsContext = createContext<{ state: NFTsStoreState; dispatch: React.Dispatch<Action> }>({
  state: initialState,
  dispatch: () => null,
});

const MyNFTsProvider = ({ children }: { children: ReactChild }) => {
  const [state, dispatch] = useReducer(myNFTsReducer, initialState);

  const contextData = { state, dispatch };

  return <MyNFTsContext.Provider value={contextData}>{children}</MyNFTsContext.Provider>;
};

export { MyNFTsContext, MyNFTsProvider };
