import EventEmitter from 'events';
import React, { createContext, ReactChild, useReducer } from 'react';
import { ethers } from 'ethers';
import { useStoredAccount } from 'hooks';

interface RequestArguments {
  method: string;
  params?: unknown[] | object;
}

export interface AddEthereumChainParameter {
  chainId: string; // A 0x-prefixed hexadecimal string
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: number;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[]; // Currently ignored.
}

export const AVALANCHE_MAINNET_PARAMS = {
  chainId: '0xA86A',
  chainName: 'Avalanche Mainnet C-Chain',
  nativeCurrency: {
    name: 'Avalanche',
    symbol: 'AVAX',
    decimals: 18,
  },
  rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
  blockExplorerUrls: ['https://cchain.explorer.avax.network/'],
} as AddEthereumChainParameter;

export const AVALANCHE_TESTNET_PARAMS = {
  chainId: '0xA869',
  chainName: 'Avalanche Testnet C-Chain',
  nativeCurrency: {
    name: 'Avalanche',
    symbol: 'AVAX',
    decimals: 18,
  },
  rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
  blockExplorerUrls: ['https://cchain.explorer.avax-test.network/'],
} as AddEthereumChainParameter;

export interface Provider extends EventEmitter {
  isMetaMask: boolean;
  isConnected(): boolean;
  // eslint-disable-next-line no-unused-vars
  request(args: RequestArguments): Promise<unknown>;
}

export type SupportedChains =
  | 'local'
  | 'mainnet'
  | 'ropsten'
  | 'rinkeby'
  | 'goerli'
  | 'kovan'
  | 'avalanche'
  | 'avalanche-test'
  | 'unknown';

export interface ChainInfo {
  id: string | null;
  name: SupportedChains;
}

export interface MetamaskStoreState {
  accounts: Array<string>;
  chain: ChainInfo;
  isConnected: boolean;
  provider: ethers.providers.Web3Provider | null;
}

type typeStateMapKeys = 'SET_ACCOUNT' | 'SET_CHAIN' | 'SET_CONNECTED' | 'SET_PROVIDER';

// eslint-disable-next-line no-unused-vars
const typeStateMap: { [key in typeStateMapKeys]: string } = {
  SET_ACCOUNT: 'accounts',
  SET_CHAIN: 'chain',
  SET_CONNECTED: 'isConnected',
  SET_PROVIDER: 'provider',
};

const initialState: MetamaskStoreState = {
  accounts: [],
  chain: { id: null, name: 'unknown' },
  isConnected: false,
  provider: null,
};

interface Action {
  type: typeStateMapKeys;
  payload: any;
}

const MetaStateContext = createContext<MetamaskStoreState>(initialState);
const MetaDispatchContext = createContext<React.Dispatch<Action>>(() => {});

const MetamaskStateProvider = ({ children }: { children: ReactChild }) => {
  const { account: storedAccount, storeAccount, removeStoredAccount } = useStoredAccount();

  const reducer = (state: MetamaskStoreState, action: Action) => {
    const stateName = typeStateMap[action.type];

    if (action.type === 'SET_ACCOUNT') {
      const [acc] = action.payload;
      storeAccount(acc);
    }

    if (action.type === 'SET_CONNECTED' && action.payload === false) {
      removeStoredAccount();
    }

    return { ...state, [stateName]: action.payload };
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <MetaDispatchContext.Provider value={dispatch}>
      <MetaStateContext.Provider
        value={{ ...state, accounts: storedAccount ? [storedAccount] : state.accounts }}
      >
        {children}
      </MetaStateContext.Provider>
    </MetaDispatchContext.Provider>
  );
};

export { typeStateMap, MetaStateContext, MetaDispatchContext, MetamaskStateProvider };
