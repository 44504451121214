import environment from 'config';
import { NavLink } from 'react-router-dom';
import { ConnectDisconnectButton } from 'shared/components';
import logoImage from 'assets/images/logo.png';

import './TopBar.scss';

export default function TopBar(): JSX.Element {
  return (
    <nav className="TopBar">
      <span className="logo">
        <img src={logoImage} alt="Logo Mecha" />
      </span>
      <ul className="navigation">
        {environment.development && (
          <li>
            <NavLink to="/market" activeClassName="active">
              Market
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to="/my-nfts" activeClassName="active">
            My NFTs
          </NavLink>
        </li>
        <li>
          <NavLink to="/nfts/account" activeClassName="active">
            NFTs search
          </NavLink>
        </li>
        {environment.development && (
          <li>
            <NavLink to="/test" activeClassName="active">
              Test
            </NavLink>
          </li>
        )}
      </ul>
      <ConnectDisconnectButton />
    </nav>
  );
}
