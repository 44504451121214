import { Observable, Subject } from 'rxjs';

export interface PayloadPromise {
  promise: () => Promise<any>;
  payload?: any;
}
export interface QueueErrorPromise extends PayloadPromise {
  error: any;
}

export interface CompletedPromise extends PayloadPromise {
  response: any;
}

export interface OnQueueCompleted {
  completedPromises: Array<CompletedPromise>;
  errors: Array<QueueErrorPromise>;
}

export class PromiseQueue {
  private completed: Subject<OnQueueCompleted>;
  private runningPromises: Array<PayloadPromise | null>;
  private completedPromises: Array<CompletedPromise>;
  private todoPromises: Array<PayloadPromise>;
  private errors: Array<QueueErrorPromise>;

  /**
   * @param {Array<PayloadPromise>} promises - Promises array to handle simultaniously
   * @param {number} [maxConcurrent=1] - Max Concurrent Requests (1 by default)
   */
  constructor(promises: Array<PayloadPromise>, maxConcurrent = 1) {
    this.completed = new Subject<OnQueueCompleted>();

    this.completedPromises = [];
    this.errors = [];
    this.runningPromises = [];
    this.todoPromises = promises;
    this.runningPromises = new Array<PayloadPromise>(maxConcurrent);
  }

  get onComplete(): Observable<OnQueueCompleted> {
    return this.completed.asObservable();
  }

  run(): void {
    if (this.todoPromises.length === 0) {
      this.completed.next({
        completedPromises: this.completedPromises,
        errors: this.errors,
      });
      this.completed.complete();
    }

    for (let i = 0; i < this.runningPromises.length; i += 1) {
      const promise = this.todoPromises.shift();

      if (promise !== undefined) {
        this.handleNewPromise(promise, i);
      }
    }
  }

  private handleNewPromise(
    payloadPromise: PayloadPromise,
    index: number,
  ): void {
    const { promise, payload } = payloadPromise;

    this.runningPromises[index] = payloadPromise;

    promise()
      .then((response) => {
        this.completedPromises.push({ response, promise, payload });
      })
      .catch((error) => {
        this.errors.push({ error, promise, payload });
      })
      .finally(() => this.handleNextPromise(index));
  }

  private handleNextPromise(index: number): void {
    this.runningPromises[index] = null;
    const promise = this.todoPromises.shift();

    if (promise !== undefined) {
      this.handleNewPromise(promise, index);
      return;
    }

    if (this.runningPromisesIsEmpty()) {
      this.completed.next({
        completedPromises: this.completedPromises,
        errors: this.errors,
      });
      this.completed.complete();
    }
  }

  private runningPromisesIsEmpty(): boolean {
    return this.runningPromises.every((element) => element === null);
  }
}
