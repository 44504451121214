import { useMechaContract, useTabs, use3DiceContract } from 'hooks';

import MyMechasView from './MyMechasView/MyMechasView';
import My3DiceView from './My3DiceView/My3DiceView';

import './AccountView.scss';

type AccountViewTab = 'mecha' | 'dice';

const AccountViewTabs: { [key: string]: AccountViewTab } = {
  MECHA: 'mecha',
  DICE: 'dice',
};

interface AccountViewProps {
  currentAccount: string;
  isOwnAccount: boolean;
}

const AccountView = (props: AccountViewProps): JSX.Element => {
  const { currentAccount, isOwnAccount } = props;
  const { contract: mechaContract, tokenAddress } = useMechaContract();
  const { contract: threeDiceContract, tokenAddress: threeDiceTokenAddress } = use3DiceContract();

  const { checkCurrentTabIs, setCurrentTab } = useTabs<AccountViewTab>(
    Object.values(AccountViewTabs),
    AccountViewTabs.MECHA,
  );

  return (
    <div className="AccountView">
      <div className="tabs">
        <div className="tab-titles d-flex flex-row">
          <div className={`tab-title ${checkCurrentTabIs(AccountViewTabs.MECHA) ? 'active' : ''}`}>
            <button
              className="clickable btn"
              type="button"
              onClick={() => {
                setCurrentTab('mecha');
              }}
            >
              Mecha Chassis
            </button>
          </div>
          <div className={`tab-title ${checkCurrentTabIs(AccountViewTabs.DICE) ? 'active' : ''}`}>
            <button
              className="clickable btn"
              type="button"
              onClick={() => {
                setCurrentTab('dice');
              }}
            >
              3Dice
            </button>
          </div>
        </div>
        <div className="tab-contents">
          {checkCurrentTabIs(AccountViewTabs.MECHA) && (
            <div className="tab-content">
              {mechaContract && tokenAddress && (
                <MyMechasView currentAccount={currentAccount} isOwnAccount={isOwnAccount} />
              )}
            </div>
          )}
          {checkCurrentTabIs(AccountViewTabs.DICE) && (
            <div className="tab-content">
              {threeDiceContract && threeDiceTokenAddress && (
                <>
                  <My3DiceView currentAccount={currentAccount} isOwnAccount={isOwnAccount} />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountView;
