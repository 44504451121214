const MECHA_TOKEN_ADDRRESS = {
  mainnet: '0xfF56b92AEC0F5Acd23f66430c3B7A62af0586A47',
  testnet: '0x6AE0a9FA89f6B2cF1cb50f96f726F96f28fcee5d',
};

const THREEDICE_TOKEN_ADDRRESS = {
  mainnet: '0x0452d48C68050e31cDDd43F6C6741AfdfBF94721',
  testnet: '0x7Fc2B5d4Dad49753Cff7F71d5944903c525bCB27',
};

const RPC_PROVIDER = {
  test: 'https://api.avax-test.network/ext/bc/C/rpc',
  prod: 'https://api.avax.network/ext/bc/C/rpc',
};

export interface Environment {
  development: boolean;
  production: boolean;
  staging: boolean;
  useMocks: boolean;
  mechaTokenAddress: {
    mainnet: string;
    testnet: string;
  };
  threeDiceTokenAddress: {
    mainnet: string;
    testnet: string;
  };
  provider: {
    test: string;
    prod: string;
  };
}

interface Environments {
  dev: Environment;
  prod: Environment;
  staging: Environment;
}

const environment: Environments = {
  dev: {
    development: true,
    staging: false,
    production: false,
    useMocks: false,
    mechaTokenAddress: MECHA_TOKEN_ADDRRESS,
    threeDiceTokenAddress: THREEDICE_TOKEN_ADDRRESS,
    // mechaTokenAddress: '0xfF56b92AEC0F5Acd23f66430c3B7A62af0586A47',
    provider: RPC_PROVIDER,
  },
  staging: {
    development: false,
    staging: true,
    production: false,
    useMocks: false,
    mechaTokenAddress: MECHA_TOKEN_ADDRRESS,
    threeDiceTokenAddress: THREEDICE_TOKEN_ADDRRESS,
    provider: RPC_PROVIDER,
  },
  prod: {
    development: false,
    staging: false,
    production: true,
    useMocks: false,
    mechaTokenAddress: MECHA_TOKEN_ADDRRESS,
    threeDiceTokenAddress: THREEDICE_TOKEN_ADDRRESS,
    provider: RPC_PROVIDER,
  },
};

export default environment;
