import { Contract } from 'ethers';
import { use3DiceContract, useMechaContract } from 'hooks';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import {
  MechaNFTContractViewer,
  NFTTransferForm,
  ThreeDiceNFTContractViewer,
} from 'shared/components';
import { MechaNFTContract, ThreeDiceNFTContract } from 'shared/models';
import { NFTType } from 'utils/types';

import loadingImage from 'assets/images/1480.gif';

import './NFTDetailView.scss';

interface NFTDetailContainerProps {
  tokenId: string;
  account: string;
  typeNFT: NFTType;
  contract: Contract;
}

const NFTDetailContainer = (props: NFTDetailContainerProps): JSX.Element => {
  const { tokenId, account, typeNFT, contract } = props;
  const [nft, setNFT] = useState<MechaNFTContract | ThreeDiceNFTContract | null>(null);
  const [isOwnAccount, setIsOwnAccount] = useState<boolean>(false);
  const [transfered, setTransfered] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const loadNFT = async () => {
      let loadedNFT: MechaNFTContract | ThreeDiceNFTContract | null = null;

      try {
        if (typeNFT === 'mecha') {
          loadedNFT = await MechaNFTContract.createInstance(tokenId, contract);
        } else {
          loadedNFT = await ThreeDiceNFTContract.createInstance(tokenId, contract);
        }
      } catch (error) {
        history.push('/not-found');
        return;
      }

      setNFT(loadedNFT);
    };

    loadNFT();
  }, [typeNFT, tokenId, account, history, contract]);

  useEffect(() => {
    if (!nft) return;

    setIsOwnAccount(nft.owner?.toLowerCase() === account?.toLowerCase());
  }, [nft, account]);

  if (nft === null) {
    return (
      <div className="flex-1 text-align-center">
        <img src={loadingImage} alt="loading" width={40} height={40} />
      </div>
    );
  }

  return (
    <>
      {contract && nft && (
        <div className="NFTDetail">
          <div className="contract-viewer">
            {nft instanceof MechaNFTContract && (
              <MechaNFTContractViewer contract={nft} contractAddress={contract.address} />
            )}
            {nft instanceof ThreeDiceNFTContract && (
              <ThreeDiceNFTContractViewer contract={nft} contractAddress={contract.address} />
            )}
          </div>
          {isOwnAccount && !transfered && (
            <NFTTransferForm
              nft={nft}
              account={account}
              contract={contract}
              onTransferFinished={setTransfered}
            />
          )}
        </div>
      )}
    </>
  );
};

interface NFTDetailViewProps {
  account: string;
  tokenId: string;
  typeNFT: NFTType;
}

export default function NFTDetailView(props: NFTDetailViewProps): JSX.Element {
  const { account, tokenId, typeNFT } = props;
  const { contract: mechaContract } = useMechaContract();
  const { contract: threeDiceContract } = use3DiceContract();
  const history = useHistory();
  const contract = typeNFT === 'mecha' ? mechaContract : threeDiceContract;

  return (
    <div className="NFTDetailView">
      {contract && (
        <>
          <span className="go-back" onClick={history.goBack}>
            <FontAwesomeIcon className="icon-go-back" icon={faChevronLeft} />
            Back
          </span>
          <NFTDetailContainer
            tokenId={tokenId}
            account={account}
            typeNFT={typeNFT}
            contract={contract}
          />
        </>
      )}
    </div>
  );
}
