import dayjs from 'utils/dayjs';

import { useFavStorage } from 'hooks';
import { FavoriteButton } from 'shared/components';
import { MechaNFTContract } from 'shared/models';

import './MechaNFTContractViewer.scss';
import MechaContractAttributes from '../MechaContractAttributes/MechaContractAttributes';

interface MechaNFTContractViewerProps {
  contract: MechaNFTContract;
  contractAddress: string;
  // eslint-disable-next-line no-unused-vars
  contractClick?: (contract: MechaNFTContract) => any;
}

const MechaNFTContractViewer = ({
  contract,
  contractAddress,
  contractClick,
}: MechaNFTContractViewerProps): JSX.Element => {
  const { storage, toggleFav } = useFavStorage();
  const storageMecha = storage.get('mecha');
  const linkNFTrade = `https://app.nftrade.com/assets/avalanche/${contractAddress}/${contract.tokenId.toString()}`;
  const onContractClick = () => {
    if (contractClick) {
      contractClick(contract);
    }
  };

  const onToggleFav = () => {
    toggleFav('mecha', contract.tokenId.toString());
  };

  return (
    <div className="MechaNFTContractViewer">
      <FavoriteButton
        isFavorite={storageMecha?.get(contract.tokenId.toString()) || false}
        onToggleFav={onToggleFav}
      />
      <div className={`${contractClick ? 'clickable' : ''}`} onClick={onContractClick}>
        <h1>{contract.name}</h1>
        <p className="text-align-right">{dayjs(contract.ownedSince).fromNow()}</p>
        <div className="contract-content">
          <img className="contract-image" alt="mecha" src={contract?.image} />
          <MechaContractAttributes contract={contract} />
        </div>
        <a className="nav-link" href={linkNFTrade}>
          View on NFTrade
        </a>
      </div>
    </div>
  );
};

MechaNFTContractViewer.defaultProps = {
  contractClick: undefined,
};

export default MechaNFTContractViewer;
