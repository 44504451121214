import { ChangeEvent, useState } from 'react';
import { Contract } from 'ethers';
import { TransactionResponse } from '@ethersproject/providers';

import { useMetamask } from 'hooks';
import loadingImage from 'assets/images/1480.gif';

import './NFTTransferForm.scss';
import { MechaNFTContract, ThreeDiceNFTContract } from 'shared/models';

interface NFTTransferFormProps {
  nft: MechaNFTContract | ThreeDiceNFTContract;
  account: string;
  contract: Contract;

  // eslint-disable-next-line react/require-default-props, no-unused-vars
  onTransferFinished?: (result: boolean) => void;
}

const NFTTransferForm = (props: NFTTransferFormProps): JSX.Element => {
  const { contract, nft, account, onTransferFinished } = props;
  const [isTransfering, setIsTransfering] = useState<boolean>(false);
  const [address, setAddress] = useState<string>('');
  const { metaState } = useMetamask();

  const isAddressValid = (addr: string) => {
    const addressRe = /^0x[a-fA-F0-9]{40}$/g;
    return addressRe.test(addr);
  };

  const transfer = async () => {
    if (
      !isAddressValid(address) ||
      contract === undefined ||
      metaState.provider === undefined ||
      isTransfering
    ) {
      return;
    }

    setIsTransfering(true);
    let result = false;

    try {
      let transaction: TransactionResponse;

      if (nft instanceof MechaNFTContract) {
        transaction = await contract.transferFrom(account, address, nft.tokenId);
      } else if (nft instanceof ThreeDiceNFTContract) {
        transaction = await contract.transferFrom(account, address, nft.tokenId);
      } else {
        return;
      }

      await transaction.wait();
      result = true;
    } catch (error) {
      // TODO: Handle error
      result = false;
    }

    setIsTransfering(false);

    if (onTransferFinished) onTransferFinished(result);
  };

  return (
    <div className="NFTTransferForm">
      <h3>Transfer to</h3>
      <div className="form-group d-flex">
        <label htmlFor="address" className="form-label flex-2">
          Account Address
        </label>
        <input
          className={`form-control flex-3 ${isAddressValid(address) ? 'valid' : 'invalid'}`}
          type="text"
          value={address}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const target: HTMLInputElement = event.target as HTMLInputElement;
            setAddress(target.value);
          }}
          id="address"
        />
      </div>
      <div className="d-flex btn-transfer">
        <button
          type="button"
          onClick={transfer}
          disabled={!isAddressValid(address) || isTransfering}
          className="btn btn-primary"
        >
          Transfer
        </button>
      </div>
      {isTransfering && (
        <div>
          Transfering...
          <img src={loadingImage} alt="loading" width={40} height={40} />
        </div>
      )}
    </div>
  );
};

NFTTransferForm.onTransferFinished = {
  contractClick: undefined,
};

export default NFTTransferForm;
