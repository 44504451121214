import { ChangeEvent } from 'react';

import {
  MechaNFTFilterType,
  ThreeDiceNFTFiltersI,
  ThreeDiceNFTFilterType,
  THREE_DICE_DEFAULT_FILTERS,
} from 'shared/interfaces';

import { FavoriteFilters, Tooltip } from 'shared/components';

import './ThreeDiceNFTFilters.scss';

interface ThreeDiceNFTFiltersProps {
  // eslint-disable-next-line no-unused-vars
  setFilters: (filters: ThreeDiceNFTFiltersI) => void;
  filters: ThreeDiceNFTFiltersI;
}

export default function ThreeDiceNFTFilters(props: ThreeDiceNFTFiltersProps): JSX.Element {
  const [MIN_RANGE_POSSIBLE, MAX_RANGE_POSSIBLE] = [3, 18];
  const { filters, setFilters } = props;

  const clearFilters = () => {
    setFilters(THREE_DICE_DEFAULT_FILTERS);
  };

  const clearFilter = (filterKey: ThreeDiceNFTFilterType) => {
    setFilters({ ...filters, [filterKey]: THREE_DICE_DEFAULT_FILTERS[filterKey] });
  };

  const onFilterInputChanged = (
    event: ChangeEvent<HTMLInputElement>,
    filterKey: ThreeDiceNFTFilterType,
  ) => {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const value = parseInt(target.value, 10);

    setFilters({
      ...filters,
      [filterKey]: value || null,
    });
  };

  const onFilterFavoriteChanged = (value: boolean, filterKey: MechaNFTFilterType) => {
    setFilters({ ...filters, [filterKey]: value });
  };

  return (
    <div className="ThreeDiceNFTFilters w-100">
      <div className="d-flex align-items-center">
        <div className="flex-3">
          <h2>Filters</h2>
        </div>
        <div className="filter-clear flex-1">
          <button type="button" onClick={clearFilters} className="btn btn-primary">
            Clear
          </button>
        </div>
      </div>
      <div className="filter-controller checkbox-favorite">
        <FavoriteFilters
          favorite={filters.favorite}
          onChangeFav={(value) => {
            onFilterFavoriteChanged(value, 'favorite');
          }}
        />
      </div>
      <div className="filter-controller">
        <label htmlFor="maxim" className="label-range">
          <Tooltip text="Contains at least one roll with a value equal to or greater than" />
          High roll: {filters.maxim || '-'}
          <input
            type="range"
            name=""
            id="maxim"
            max={MAX_RANGE_POSSIBLE}
            min={MIN_RANGE_POSSIBLE}
            value={filters.maxim || MIN_RANGE_POSSIBLE}
            onChange={(event) => {
              onFilterInputChanged(event, 'maxim');
            }}
          />
        </label>
        <button
          type="button"
          className="filter-clear flex-1 btn btn-primary"
          onClick={() => {
            clearFilter('maxim');
          }}
        >
          X
        </button>
      </div>
      <div className="filter-controller">
        <label htmlFor="minim" className="label-range">
          <Tooltip text="Contains at least one roll with a value equal to or lower than" />
          Low roll: {filters.minim || '-'}
          <input
            type="range"
            name=""
            id="minim"
            max={MAX_RANGE_POSSIBLE}
            min={MIN_RANGE_POSSIBLE}
            value={filters.minim || MAX_RANGE_POSSIBLE}
            onChange={(event) => {
              onFilterInputChanged(event, 'minim');
            }}
          />
        </label>
        <button
          type="button"
          className="filter-clear flex-1 btn btn-primary"
          onClick={() => {
            clearFilter('minim');
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
