import { Contract } from 'ethers';
import { formatFixed } from '@ethersproject/bignumber';
import { NFTBase } from './NFTBase';

export type MechaTrailTypes =
  | 'Generation'
  | 'Class'
  | 'Body'
  | 'Color'
  | 'Material'
  | 'Power Source'
  | 'Pilot'
  | 'Weapon Slots'
  | 'Mod Slots';

interface MechaContractAttributesDTO {
  // eslint-disable-next-line camelcase
  display_type: string;
  // eslint-disable-next-line camelcase
  trait_type: MechaTrailTypes;
  value: any;
}
export interface MechaAttributes {
  mass: number;
  // massClass: number;
  massClassString: string;
  massString: string;
  generation: string;
  body: string;
  color: string;
  colorCode: string;
  material: string;
  powerSource: string;
  modSlots: number;
  // modSlotsText: string;
  weaponSlots: number;
  // weaponSlotsText: string;
  pilot: string;
}
export interface MechaContractMetadataI {
  name: string;
  description: string;
  image: string;
  attributes: Array<MechaContractAttributesDTO>;
}

export class MechaNFTContract
  extends NFTBase<
    MechaContractAttributesDTO,
    MechaAttributes,
    MechaContractMetadataI
  >
  implements MechaAttributes
{
  mass: number;
  // massClass: number;
  massClassString: string;
  massString: string;
  generation: string;
  body: string;
  color: string;
  colorCode: string;
  material: string;
  powerSource: string;
  modSlots: number;
  // modSlotsText: string;
  weaponSlots: number;
  // weaponSlotsText: string;
  pilot: string;

  constructor(
    tokenId: string,
    data: string,
    owner: string,
    ownedSince?: string,
  ) {
    super(tokenId, data, owner, ownedSince);

    this.mass = this.attributes.mass;
    // this.massClass = this.attributes.massClass;
    this.massClassString = this.attributes.massClassString;
    this.massString = this.attributes.massString;
    this.generation = this.attributes.generation;
    this.body = this.attributes.body;
    this.color = this.attributes.color;
    this.colorCode = this.attributes.colorCode;
    this.material = this.attributes.material;
    this.powerSource = this.attributes.powerSource;
    this.modSlots = this.attributes.modSlots;
    // this.modSlotsText = this.attributes.modSlotsText;
    this.weaponSlots = this.attributes.weaponSlots;
    // this.weaponSlotsText = this.attributes.weaponSlotsText;
    this.pilot = this.attributes.pilot;
  }

  // eslint-disable-next-line class-methods-use-this
  formatMetadata(metadata: string): MechaContractMetadataI {
    return JSON.parse(
      Buffer.from(metadata.substring(29), 'base64').toString(),
    ) as MechaContractMetadataI;
  }

  // eslint-disable-next-line class-methods-use-this
  formatAttributes(
    metadataAttributes: MechaContractAttributesDTO[],
  ): MechaAttributes {
    const mechaContractAttributes: MechaAttributes = {
      mass: 0,
      // massClass: this.massClass,
      massClassString:
        metadataAttributes.find((a) => a.trait_type === 'Class')?.value || 0,
      massString: '',
      generation:
        metadataAttributes.find((a) => a.trait_type === 'Generation')?.value ||
        0,
      body:
        metadataAttributes.find((a) => a.trait_type === 'Body')?.value || '',
      color:
        metadataAttributes.find((a) => a.trait_type === 'Color')?.value || '',
      colorCode: '',
      material:
        metadataAttributes.find((a) => a.trait_type === 'Material')?.value ||
        '',
      powerSource:
        metadataAttributes.find((a) => a.trait_type === 'Power Source')
          ?.value || '',
      modSlots:
        metadataAttributes.find((a) => a.trait_type === 'Mod Slots')?.value ||
        '',
      // modSlotsText: this.modSlotsText,
      weaponSlots:
        metadataAttributes.find((a) => a.trait_type === 'Weapon Slots')
          ?.value || '',
      // weaponSlotsText: this.weaponSlotsText,
      pilot:
        metadataAttributes.find((a) => a.trait_type === 'Pilot')?.value || '',
    };
    return mechaContractAttributes;
  }

  static async createInstance(
    tokenId: string,
    ethContract: Contract,
    ownedSince?: string,
  ): Promise<MechaNFTContract> {
    const data = await ethContract.tokenURI(tokenId);
    const ownerId = await ethContract.ownerOf(tokenId);
    return new MechaNFTContract(tokenId, data, ownerId, ownedSince);
  }

  static async createInstanceAllData(
    tokenId: string,
    ethContract: Contract,
  ): Promise<MechaNFTContract> {
    const data = await ethContract.tokenURI(tokenId);
    const ownerId = await ethContract.ownerOf(tokenId);

    const getMass = await ethContract.getMass(tokenId);
    const mass = Number.parseInt(formatFixed(getMass), 10);
    // const getMassClass = await ethContract.getMassClass(tokenId);
    const getMassClassString = await ethContract.getMassClassString(tokenId);
    // const getMassString = await ethContract.getMassString(tokenId);
    const getGeneration = await ethContract.getGeneration(tokenId);
    const getBody = await ethContract.getBody(tokenId);
    const getColor = await ethContract.getColor(tokenId);
    const getColorCode = await ethContract.getColorCode(tokenId);
    const getMaterial = await ethContract.getMaterial(tokenId);
    const getPowerSource = await ethContract.getPowerSource(tokenId);
    const getModSlots = await ethContract.getModSlots(tokenId);
    // const getModSlotsText = await ethContract.getModSlotsText(tokenId);
    const getPilot = await ethContract.getPilot(tokenId);
    const getWeaponSlots = await ethContract.getWeaponSlots(tokenId);
    // const getWeaponSlotsText = await ethContract.getWeaponSlotsText(tokenId);

    // eslint-disable-next-line no-unused-vars
    const attributes = {
      mass,
      // massClass: getMassClass,
      massClassString: getMassClassString,
      massString: `${mass}t`,
      generation: getGeneration,
      body: getBody,
      color: getColor,
      colorCode: getColorCode,
      material: getMaterial,
      powerSource: getPowerSource,
      modSlots: Number.parseInt(formatFixed(getModSlots), 10),
      // modSlotsText: getModSlotsText,
      weaponSlots: Number.parseInt(formatFixed(getWeaponSlots), 10),
      // weaponSlotsText: getWeaponSlotsText,
      pilot: getPilot,
    };

    return new MechaNFTContract(tokenId, data, ownerId);
  }
}
