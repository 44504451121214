export type MechaNFTFilterType =
  | 'bodies'
  | 'materials'
  | 'pilots'
  | 'colors'
  // | 'colorCodes'
  | 'powerSources'
  | 'massClasses'
  | 'favorite';

export type MechaNFTFiltersI = {
  bodies: string[];
  materials: string[];
  pilots: string[];
  colors: string[];
  // colorCodes: string[];
  powerSources: string[];
  massClasses: string[];
  favorite: boolean;
};

export const MECHA_DEFAULT_FILTERS: MechaNFTFiltersI = {
  bodies: [],
  materials: [],
  pilots: [],
  colors: [],
  // colorCodes: [],
  powerSources: [],
  massClasses: [],
  favorite: false,
};
