interface FavoriteFiltersPropsI {
  favorite: boolean;
  // eslint-disable-next-line no-unused-vars
  onChangeFav: (value: boolean) => void;
}

export default function FavoriteFilters(props: FavoriteFiltersPropsI): JSX.Element {
  const { favorite, onChangeFav } = props;

  return (
    <label htmlFor="favorite" className="label-radio">
      Favorites
      <input
        className="float-right"
        id="favorite"
        type="checkbox"
        value="favorite"
        checked={favorite}
        onChange={() => {
          onChangeFav(!favorite);
        }}
      />
    </label>
  );
}
