import detectEthereumProvider from '@metamask/detect-provider';
import EventEmitter from 'events';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import {
  MetaStateContext,
  MetaDispatchContext,
  ChainInfo,
  SupportedChains,
  AddEthereumChainParameter,
} from 'context/metamaskStore';
import { ethers } from 'ethers';
import useIsMounted from './useIsMounted';

interface RequestArguments {
  method: string;
  params?: unknown[] | object;
}

interface Provider extends EventEmitter {
  isMetaMask: boolean;
  isConnected(): boolean;
  // eslint-disable-next-line no-unused-vars
  request(args: RequestArguments): Promise<unknown>;
}

const chains = (chainId: string): SupportedChains => {
  if (!!Number(chainId) && chainId.length > 9) {
    return 'local';
  }
  switch (chainId) {
    case '1':
      return 'mainnet';
    case '3':
      return 'ropsten';
    case '4':
      return 'rinkeby';
    case '5':
      return 'goerli';
    case '42':
      return 'kovan';
    case '43114':
      return 'avalanche';
    case '43113':
      return 'avalanche-test';
    default:
      return 'unknown';
  }
};

export default function useMetamask() {
  const state = useContext(MetaStateContext);
  const dispatch = useContext(MetaDispatchContext);
  const isMounted = useIsMounted();
  const isConnectCalled = useRef(false);
  const [provider, setProvider] = useState<Provider | null>(null);

  const switchEthereumChain = useCallback(
    async (ethereumChain: AddEthereumChainParameter) => {
      if (!provider) {
        // eslint-disable-next-line no-console
        console.warn('Metamask is not available.');
        return;
      }

      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: ethereumChain.chainId }],
        });
      } catch (switchError: any) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await provider.request({
              method: 'wallet_addEthereumChain',
              params: [ethereumChain],
            });
          } catch (addError) {
            // handle "add" error
          }
        }
      }
    },
    [provider],
  );

  const getAccounts = useCallback(
    async ({ requestPermission } = { requestPermission: false }): Promise<Array<string> | null> => {
      if (!provider) {
        // eslint-disable-next-line no-console
        console.warn('Metamask is not available.');
        return null;
      }

      try {
        const accounts: Array<string> = (await provider.request({
          method: requestPermission ? 'eth_requestAccounts' : 'eth_accounts',
          params: [],
        })) as Array<string>;
        if (accounts.length) {
          dispatch({ type: 'SET_CONNECTED', payload: true });
          dispatch({ type: 'SET_ACCOUNT', payload: accounts });
        }
        return accounts;
      } catch (error) {
        // TODO: Handle error
      }

      return null;
    },
    [dispatch, provider],
  );

  const getChain = useCallback(async (): Promise<ChainInfo | null> => {
    if (!provider) {
      // eslint-disable-next-line no-console
      console.warn('Metamask is not available.');
      return null;
    }
    try {
      const ethChainId: string = (await provider.request({
        method: 'eth_chainId',
        params: [],
      })) as string;
      const chainId = Number.parseInt(ethChainId, 16).toString();
      const chainInfo = { id: chainId, name: chains(chainId) };
      dispatch({
        type: 'SET_CHAIN',
        payload: chainInfo,
      });
      return chainInfo;
    } catch (error) {
      // TODO: Handle error
    }

    return null;
  }, [dispatch, provider]);

  const connect = useCallback(async () => {
    if (!provider) throw Error('Metamask is not available.');
    if (!isMounted()) throw Error('Component is not mounted.');
    if (isConnectCalled.current) throw Error('Connect method already called.');
    isConnectCalled.current = true;

    const ethProvider = new ethers.providers.Web3Provider(provider);

    dispatch({ type: 'SET_PROVIDER', payload: ethProvider });

    await getAccounts({ requestPermission: true });
    await getChain();

    provider.on('accountsChanged', (accounts: Array<string>) => {
      if (!accounts.length) dispatch({ type: 'SET_CONNECTED', payload: false });
      dispatch({ type: 'SET_ACCOUNT', payload: accounts });
    });

    provider.on('chainChanged', (newChainId: string) => {
      const chainId = Number.parseInt(newChainId, 16).toString();
      const chainInfo = { id: chainId, name: chains(chainId) };
      const newProvider = new ethers.providers.Web3Provider(provider);

      dispatch({ type: 'SET_PROVIDER', payload: newProvider });
      dispatch({ type: 'SET_CHAIN', payload: chainInfo });
    });

    isConnectCalled.current = false;
  }, [dispatch, getAccounts, getChain, provider, isMounted]);

  const disconnect = useCallback(async () => {
    dispatch({ type: 'SET_CONNECTED', payload: false });
    dispatch({ type: 'SET_ACCOUNT', payload: [] });
  }, [dispatch]);

  useEffect(() => {
    const init = async () => {
      const ethProvider: Provider = (await detectEthereumProvider()) as Provider;
      setProvider(ethProvider);
    };

    init();
  }, []);

  return {
    connect,
    disconnect,
    getAccounts,
    getChain,
    switchEthereumChain,
    metaState: state,
  };
}
