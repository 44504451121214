import { ThreeDiceAttributes, ThreeDiceNFTContract } from 'shared/models';

import './ThreeDiceContractAttributes.scss';

interface ThreeDiceContractAttributesProps {
  contract: ThreeDiceNFTContract;
}

const ThreeDiceContractAttributes = ({
  contract,
}: ThreeDiceContractAttributesProps): JSX.Element => {
  const attributes: ThreeDiceAttributes = {
    roll1: contract.roll1,
    roll2: contract.roll2,
    roll3: contract.roll3,
    roll4: contract.roll4,
    roll5: contract.roll5,
    roll6: contract.roll6,
    roll7: contract.roll7,
    roll8: contract.roll8,
  };

  return (
    <div className="ThreeDiceContractAttributes NFTContractAttributes">
      {Object.entries(attributes).map(([key, item]) => (
        <p key={key} className="contract-attributes-item">
          <strong>{item.throw}</strong> = {item.value}
        </p>
      ))}
      <div className="total-value mt-3 mb-3">
        <strong>Total value:</strong> <span>{contract.totalRolls}</span>
      </div>
    </div>
  );
};
export default ThreeDiceContractAttributes;
