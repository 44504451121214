import { FavStorageContext } from 'context/favStorageContext';
import { useContext } from 'react';
import { NFTType } from 'utils/types';

export default function useFavStorage() {
  const { storage, setStorage } = useContext(FavStorageContext);

  const toggleFav = (type: NFTType, key: string) => {
    let favsMap = storage.get(type);

    if (!favsMap) {
      favsMap = new Map<string, boolean>();
    }

    const prevValue = favsMap.get(key);

    if (prevValue === true) {
      favsMap.delete(key);
    } else {
      storage.set(type, favsMap.set(key, typeof prevValue === 'boolean' ? !prevValue : true));
    }

    setStorage(new Map(storage));
  };

  return { storage, toggleFav };
}
