import { useEffect, useState } from 'react';
import { ethers, Contract } from 'ethers';

import environment from 'config';
import abi from 'abi/Mecha.json';
import { MetamaskStoreState } from 'context/metamaskStore';
import useMetamask from './useMetamask';

const getRPCContract = (tAddress: string) => {
  const provider = new ethers.providers.JsonRpcProvider(
    environment.production ? environment.provider.prod : environment.provider.test,
  );

  const ethContract: Contract = new ethers.Contract(tAddress, abi, provider);

  return ethContract;
};

const getTokenAddress = (metaState: MetamaskStoreState) => {
  let tAddress: string | null = null;

  if (metaState.chain.name === 'avalanche') {
    tAddress = environment.mechaTokenAddress.mainnet;
  } else {
    tAddress = environment.mechaTokenAddress.testnet;
  }

  return tAddress;
};

export default function useMechaContract() {
  const { metaState } = useMetamask();
  const [tokenAddress, setTokenAddress] = useState<string>(getTokenAddress(metaState));

  const getContract = (tAddress: string) => {
    let ethContract: Contract;

    if (metaState.provider === null || !metaState.isConnected) {
      ethContract = getRPCContract(tokenAddress);
    } else {
      ethContract = new ethers.Contract(tAddress, abi, metaState.provider);
      const signer = metaState.provider.getSigner();
      ethContract = ethContract.connect(signer);
    }

    return ethContract;
  };

  const [contract, setContract] = useState<Contract>(getContract(tokenAddress));

  useEffect(() => {
    let ethContract: Contract;
    const tAddress: string = getTokenAddress(metaState);

    if (metaState.provider === null || !metaState.isConnected) {
      ethContract = getRPCContract(tokenAddress);
    } else {
      ethContract = new ethers.Contract(tAddress, abi, metaState.provider);
      const signer = metaState.provider.getSigner();
      ethContract = ethContract.connect(signer);
    }

    setContract(ethContract);
    setTokenAddress(tAddress);
  }, [metaState, tokenAddress]);

  return { contract, tokenAddress };
}
