import { ChangeEvent } from 'react';
import { ThreeDiceNFTOrderType, ThreeDiceNFTOrder } from 'shared/interfaces';
import './ThreeDiceNFTSort.scss';

interface ThreeDiceNFTSortProps {
  // eslint-disable-next-line no-unused-vars
  setSort: (sort: ThreeDiceNFTOrderType) => void;
  sort: ThreeDiceNFTOrderType;
}

export default function ThreeDiceNFTSort(props: ThreeDiceNFTSortProps): JSX.Element {
  const { setSort, sort } = props;

  const onFilterSelectChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    const target: HTMLSelectElement = event.target as HTMLSelectElement;
    setSort(target.value as ThreeDiceNFTOrderType);
  };

  return (
    <div className="ThreeDiceNFTSort">
      <h2>Order By</h2>
      <select
        value={sort}
        onChange={(event) => {
          onFilterSelectChanged(event);
        }}
      >
        {Object.entries(ThreeDiceNFTOrder).map(([key, orderElement]) => (
          <option key={key} value={orderElement}>
            {orderElement}
          </option>
        ))}
      </select>
    </div>
  );
}
