import { ThreeDiceNFTContract } from 'shared/models';
import ThreeDiceCard from '../ThreeDiceCard/ThreeDiceCard';

import './ThreeDiceNFTList.scss';

interface ThreeDiceNFTListProps {
  nfts: Array<ThreeDiceNFTContract>;
}

export default function ThreeDiceNFTList({ nfts }: ThreeDiceNFTListProps): JSX.Element {
  return (
    <div className="ThreeDiceNFTList w-100">
      {nfts.map((nft: ThreeDiceNFTContract) => (
        <div className="nft-container" key={nft.name}>
          <ThreeDiceCard contract={nft} />
        </div>
      ))}
    </div>
  );
}
