import { MechaNFTContract } from 'shared/models';
import MechaCard from '../MechaCard/MechaCard';

import './MechaNFTList.scss';

interface NFTListProps {
  nfts: Array<MechaNFTContract>;
}

export default function MechaNFTList({ nfts }: NFTListProps): JSX.Element {
  return (
    <div className="MechaNFTList w-100">
      {nfts.map((nft: MechaNFTContract) => (
        <div className="nft-container" key={nft.name}>
          <MechaCard contract={nft} />
        </div>
      ))}
    </div>
  );
}
