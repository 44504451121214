import { lastValueFrom, map } from 'rxjs';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { BigNumberish, Contract } from 'ethers';
import { formatFixed } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';

import { MyNFTsContext } from 'context/myNFTsContext';
import { MechaNFTContract, ThreeDiceNFTContract } from 'shared/models';
import {
  CompletedPromise,
  OnQueueCompleted,
  PayloadPromise,
  PromiseQueue,
} from 'utils/PromiseQueue';

import dayjs from 'utils/dayjs';
import useIsMounted from './useIsMounted';
import useMechaContract from './useMechaContract';
import use3DiceContract from './use3DiceContract';
import useAccountTransfers from './useAccountTransfers';

export default function useMyNFTs(accountAddress: string, myAccount: boolean = true) {
  const QUEUE_SIZE = 20;
  const isMounted = useIsMounted();
  const { state, dispatch } = useContext(MyNFTsContext);
  const [myMechas, setMyMechas] = useState<Array<MechaNFTContract>>([]);
  const [myThreeDices, setMyThreeDices] = useState<Array<ThreeDiceNFTContract>>([]);
  const { contract: mechaContract } = useMechaContract();
  const { contract: threeDiceContract } = use3DiceContract();
  const isLoadingMechasRef = useRef<boolean>(false);
  const isLoadingThreeDicesRef = useRef<boolean>(false);
  const accountAddressRef = useRef<string>('');
  const [isLoadingMechas, setIsLoadingMechas] = useState<boolean>(false);
  const [isLoadingThreeDices, setIsLoadingThreeDices] = useState<boolean>(false);
  const {
    getFinalOwnedTokenTransfers: getMechaTokenTransfers,
    reloadTransactions: reloadMechaTransactions,
  } = useAccountTransfers(mechaContract, accountAddress);
  const {
    getFinalOwnedTokenTransfers: getThreeDiceTokenTransfers,
    reloadTransactions: reloadThreeDiceTransactions,
  } = useAccountTransfers(threeDiceContract, accountAddress);

  // #### COMMON NFT FUNCTIONS #################################################

  const getTokenId = useCallback(
    async (contract: Contract, index: number) => {
      const tokenId = await contract.tokenOfOwnerByIndex(accountAddress, index);
      return formatFixed(tokenId);
    },
    [accountAddress],
  );

  const getBalanceOf = useCallback(
    async (contract: Contract): Promise<number> => {
      let accountBalance: BigNumberish = 0;

      try {
        accountBalance = await contract.balanceOf(accountAddress);
      } catch (error) {
        // TODO: Handle error
      }

      return Number.parseInt(formatUnits(accountBalance, 'wei'), 10);
    },
    [accountAddress],
  );

  const loadNFTs = useCallback(
    async (
      totalNFTs: number,
      // eslint-disable-next-line no-unused-vars
      loadSingleNFT: (index: number) => Promise<any>,
    ): Promise<any[]> => {
      let items: Array<any> = [];

      const mechaContractPromises: Array<PayloadPromise> = Array.from(Array(totalNFTs)).map(
        (_, index) => {
          const promise = loadSingleNFT.bind(undefined, index);

          return { promise, payload: null } as PayloadPromise;
        },
      );

      const queue = new PromiseQueue(mechaContractPromises, QUEUE_SIZE);

      queue.run();

      try {
        items = await lastValueFrom(
          queue.onComplete.pipe(
            map((result: OnQueueCompleted): Array<CompletedPromise> => result.completedPromises),
            map((completedPromises: Array<CompletedPromise>): Array<MechaNFTContract> => {
              return completedPromises.map((completedPromise: CompletedPromise) => {
                const { response } = completedPromise;
                return response;
              });
            }),
          ),
        );
      } catch (error) {
        // TODO: Handle error
        // console.error(error);
      }

      return items;
    },
    [],
  );

  // #### MECHA FUNCTIONS ######################################################

  const loadMyMecha = useCallback(
    async (index: number) => {
      const tokenId = await getTokenId(mechaContract, index);
      const tokenTransfers = await getMechaTokenTransfers();
      const tokenTransfer = tokenTransfers.get(tokenId);

      return MechaNFTContract.createInstance(
        tokenId,
        mechaContract,
        tokenTransfer ? dayjs.unix(tokenTransfer.timestamp).toISOString() : undefined,
      );
    },
    [mechaContract, getTokenId, getMechaTokenTransfers],
  );

  const loadMyMechas = useCallback(
    async (totalNFTs: number): Promise<MechaNFTContract[]> => {
      const items: MechaNFTContract[] = (await loadNFTs(totalNFTs, loadMyMecha)).map(
        (item) => item as MechaNFTContract,
      );

      return items;
    },
    [loadMyMecha, loadNFTs],
  );

  const reloadMechas = useCallback(
    async (forceReload: boolean = false) => {
      if (!isMounted()) return;

      const mechasBalance = await getBalanceOf(mechaContract);
      const length = myAccount ? state.mechas.length : myMechas.length;

      if (length !== mechasBalance || forceReload) {
        if (isLoadingMechasRef.current) return;
        setIsLoadingMechas(true);
        isLoadingMechasRef.current = true;
        reloadMechaTransactions();
        const mechas = await loadMyMechas(mechasBalance);
        isLoadingMechasRef.current = false;
        setIsLoadingMechas(false);

        if (myAccount) {
          dispatch({ type: 'SET_MECHAS', payload: mechas });
        } else {
          setMyMechas(mechas);
        }
      }
    },
    [
      getBalanceOf,
      mechaContract,
      loadMyMechas,
      dispatch,
      reloadMechaTransactions,
      isMounted,
      myAccount,
      state.mechas,
      myMechas.length,
    ],
  );

  // #### THREEDICE FUNCTIONS ##################################################

  const loadMyThreeDice = useCallback(
    async (index: number) => {
      const tokenId = await getTokenId(threeDiceContract, index);
      const tokenTransfers = await getThreeDiceTokenTransfers();
      const tokenTransfer = tokenTransfers.get(tokenId);

      return ThreeDiceNFTContract.createInstance(
        tokenId,
        threeDiceContract,
        tokenTransfer ? dayjs.unix(tokenTransfer.timestamp).toISOString() : undefined,
      );
    },
    [threeDiceContract, getTokenId, getThreeDiceTokenTransfers],
  );

  const loadMyThreeDices = useCallback(
    async (totalNFTs: number): Promise<ThreeDiceNFTContract[]> => {
      const items: ThreeDiceNFTContract[] = (await loadNFTs(totalNFTs, loadMyThreeDice)).map(
        (item) => item as ThreeDiceNFTContract,
      );

      return items;
    },
    [loadMyThreeDice, loadNFTs],
  );

  const reloadThreeDices = useCallback(
    async (forceReload: boolean = false) => {
      if (!isMounted()) return;

      const threeDiceBalance = await getBalanceOf(threeDiceContract);
      const length = myAccount ? state.threeDices.length : myThreeDices.length;

      if (length !== threeDiceBalance || forceReload) {
        if (isLoadingThreeDicesRef.current) return;
        setIsLoadingThreeDices(true);
        isLoadingThreeDicesRef.current = true;
        reloadThreeDiceTransactions();
        const threeDices = await loadMyThreeDices(threeDiceBalance);
        isLoadingThreeDicesRef.current = false;
        setIsLoadingThreeDices(false);

        if (myAccount) {
          dispatch({ type: 'SET_TRHEEDICES', payload: threeDices });
        } else {
          setMyThreeDices(threeDices);
        }
      }
    },
    [
      getBalanceOf,
      threeDiceContract,
      loadMyThreeDices,
      dispatch,
      reloadThreeDiceTransactions,
      isMounted,
      myAccount,
      state.threeDices,
      myThreeDices.length,
    ],
  );

  useEffect(() => {
    if (accountAddressRef.current === accountAddress) {
      return;
    }

    accountAddressRef.current = accountAddress;

    reloadMechas(true);
    reloadThreeDices(true);
  }, [reloadMechas, reloadThreeDices, accountAddress]);

  return {
    mechas: myAccount ? state.mechas : myMechas,
    threeDices: myAccount ? state.threeDices : myThreeDices,
    isLoadingMechas,
    isLoadingThreeDices,
    reloadThreeDices,
    reloadMechas,
  };
}
