import { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import { useMetamask, useStoredAccount } from 'hooks';
import {
  LoginView,
  MarketView,
  NFTAccountView,
  NFTDetailView,
  NotFoundView,
  TestView,
} from 'views';
import { AvaxNetworkWarning, Footer, ProtectedRoute, TopBar } from 'shared/components';
import { isNFTType, NFTType } from 'utils/types';
import environment from 'config';

import './App.scss';

export default function App() {
  const { metaState, connect } = useMetamask();
  const { account: storedAccount } = useStoredAccount();
  const [cAccount] = metaState.accounts;
  const [isCheckingAccounts, setIsCheckingAccounts] = useState<boolean>(true);

  const checkShowWarning = () => {
    return (
      metaState.isConnected &&
      metaState?.chain.name !== 'avalanche' &&
      metaState?.chain.name !== 'avalanche-test'
    );
  };

  useEffect(() => {
    const init = async () => {
      setIsCheckingAccounts(true);

      if (storedAccount) {
        try {
          await connect();
        } catch (error) {
          // TODO: Handle error?
        }
      }

      setIsCheckingAccounts(false);
    };

    init();
  }, [connect, storedAccount]);

  if (isCheckingAccounts) {
    // TODO: Show loading?
    return null;
  }

  return (
    <BrowserRouter>
      <div className="App">
        {checkShowWarning() && <AvaxNetworkWarning />}
        <TopBar />
        {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
        <div className="appBody">
          <Switch>
            <Route
              path="/"
              exact
              component={() =>
                metaState.accounts.length ? <Redirect to="/my-nfts" /> : <LoginView />
              }
            />
            <ProtectedRoute exact path="/market" component={MarketView} />
            <ProtectedRoute exact path="/my-nfts" component={NFTAccountView} />
            <Route exact path="/nfts/account/:account?" component={NFTAccountView} />
            <Route
              exact
              path="/nfts/:typeNFT/:tokenId"
              render={(props: RouteComponentProps<{ typeNFT: string; tokenId: string }>) => {
                const { typeNFT, tokenId } = props.match.params;

                if (!isNFTType(typeNFT)) {
                  return <Redirect to="/not-found" />;
                }

                return (
                  <NFTDetailView
                    tokenId={tokenId}
                    typeNFT={typeNFT as NFTType}
                    account={cAccount}
                  />
                );
              }}
            />
            {environment.development && <Route exact path="/test" component={TestView} />}
            <Route component={NotFoundView} />
          </Switch>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}
