import { useEffect, useState } from 'react';

export default function usePagination(itemsPerPage: number = 10, totalItems?: number) {
  const MIN_PAGE = 1;
  const [currentPage, setCurrentPage] = useState<number>(MIN_PAGE);
  const [totalPages, setTotalPages] = useState<number | undefined>(undefined);

  useEffect(() => {
    setTotalPages(
      totalItems && itemsPerPage > 0 ? Math.round(Math.ceil(totalItems / itemsPerPage)) : undefined,
    );
    setCurrentPage(MIN_PAGE);
  }, [itemsPerPage, totalItems]);

  function jumpToPage(page: number) {
    let navigate: boolean = false;

    if (totalPages === undefined) {
      navigate = page >= MIN_PAGE;
    } else {
      navigate = page >= MIN_PAGE && page <= totalPages;
    }

    setCurrentPage(navigate ? page : currentPage);
  }

  function prevPage() {
    setCurrentPage(currentPage > MIN_PAGE ? currentPage - 1 : currentPage);
  }

  function nextPage() {
    const navigate = totalPages === undefined || currentPage < totalPages;
    setCurrentPage(navigate ? currentPage + 1 : currentPage);
  }

  return { currentPage, totalPages, nextPage, prevPage, jumpToPage };
}
