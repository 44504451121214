import { FavStorageMap } from 'context/favStorageContext';
import { useCallback } from 'react';
import { NFTType } from 'utils/types';
import { useLocalStorage } from './useStorage';

const FAV_STORAGE_KEY = 'MECHA_FAV_STORAGE_KEY';
const FAV_STORAGE_KEY_MECHA: NFTType = 'mecha';
const FAV_STORAGE_KEY_THREEDICE: NFTType = 'threeDice';

interface FavLocalStorage {
  key: string;
  itemId: string;
  value: boolean;
}

export default function useStoredFavorites() {
  const {
    value: favorites,
    setValue: storeFavorites,
    remove: removeStoredFavorites,
  } = useLocalStorage(FAV_STORAGE_KEY, null);

  const saveFavoritesToStorage = useCallback(
    (storage: FavStorageMap) => {
      const newArray: FavLocalStorage[] = [];
      storage.forEach((mapItemId: Map<string, boolean>, key: string) => {
        mapItemId.forEach((value: boolean, itemId: string) => {
          newArray.push({
            key,
            itemId,
            value,
          });
        });
      });
      storeFavorites(newArray);
    },
    [storeFavorites],
  );

  const storedFavorites = useCallback((): FavStorageMap => {
    if (!favorites || !Array.isArray(favorites) || favorites.length === 0) {
      return new Map();
    }

    const newStorage: FavStorageMap = new Map<string, Map<string, boolean>>();

    newStorage.set(FAV_STORAGE_KEY_MECHA, new Map<string, boolean>());
    newStorage.set(FAV_STORAGE_KEY_THREEDICE, new Map<string, boolean>());
    favorites.forEach((item) => {
      newStorage.get(item.key)?.set(item.itemId, item.value);
    });

    return newStorage;
  }, [favorites]);

  return { storedFavorites, saveFavoritesToStorage, removeStoredFavorites };
}
