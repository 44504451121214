/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useMetamask } from 'hooks';

interface ProtectedRouteProps extends RouteProps {}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { component: Component, ...rest } = props;
  const { metaState } = useMetamask();
  const [currentAccount] = metaState.accounts;

  return (
    <Route
      {...rest}
      render={(renderProps) => {
        if (currentAccount && Component) {
          return <Component {...rest} {...renderProps} />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
};

export default ProtectedRoute;
