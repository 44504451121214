import dayjs from 'utils/dayjs';

export interface NFTMetadataI<DTO> {
  name: string;
  description: string;
  image: string;
  attributes: Array<DTO>;
}

// eslint-disable-next-line import/prefer-default-export
export abstract class NFTBase<DTO, CAttr, I extends NFTMetadataI<DTO>> {
  tokenId: string;
  owner: string;
  name: string;
  description: string;
  image: string;
  ownedSince: string;
  attributes: CAttr;

  constructor(
    tokenId: string,
    data: string,
    owner: string,
    ownedSince?: string,
  ) {
    const contractMetadata: I = this.formatMetadata(data);
    const { name, description, image, attributes } = contractMetadata;

    this.tokenId = tokenId;
    this.owner = owner;
    this.name = name;
    this.description = description;
    this.image = image;
    this.ownedSince = ownedSince
      ? dayjs(ownedSince).format('YYYY-MM-DD HH:mm:ss')
      : dayjs().format('YYYY-MM-DD HH:mm:ss');
    this.attributes = this.formatAttributes(attributes);
  }

  // eslint-disable-next-line no-unused-vars
  abstract formatMetadata(metadata: string): I;

  // eslint-disable-next-line no-unused-vars
  abstract formatAttributes(attributes: Array<DTO>): CAttr;
}
