import { NavLink } from 'react-router-dom';

import dayjs from 'utils/dayjs';
import { useFavStorage } from 'hooks';
import { FavoriteButton } from 'shared/components';
import { ThreeDiceNFTContract } from 'shared/models';

import './ThreeDiceCard.scss';
import ThreeDiceContractAttributes from '../ThreeDiceContractAttributes/ThreeDiceContractAttributes';

interface ThreeDiceCardProps {
  contract: ThreeDiceNFTContract;
}

function ThreeDiceCard({ contract }: ThreeDiceCardProps): JSX.Element {
  const { storage, toggleFav } = useFavStorage();
  const storageMecha = storage.get('threeDice');

  const onToggleFav = () => {
    toggleFav('threeDice', contract.tokenId.toString());
  };

  return (
    <div className="ThreeDiceCard nft-card">
      <div className="nft-card-header">
        <FavoriteButton
          isFavorite={storageMecha?.get(contract.tokenId.toString()) || false}
          onToggleFav={onToggleFav}
        />
      </div>
      <div className="nft-card-body">
        <p className="text-align-right">{dayjs(contract.ownedSince).fromNow()}</p>
        <NavLink className="nft-card-link" to={`/nfts/threeDice/${contract.tokenId}`}>
          <h1>{contract.name}</h1>
          <div className="contract-content">
            <ThreeDiceContractAttributes contract={contract} />
          </div>
        </NavLink>
      </div>
    </div>
  );
}

export default ThreeDiceCard;
