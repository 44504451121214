import { NavLink } from 'react-router-dom';

import dayjs from 'utils/dayjs';
import { useFavStorage } from 'hooks';
import { FavoriteButton } from 'shared/components';
import { MechaNFTContract } from 'shared/models';
import MechaContractAttributes from '../MechaContractAttributes/MechaContractAttributes';

import './MechaCard.scss';

interface MechaCardProps {
  contract: MechaNFTContract;
}

function MechaCard({ contract }: MechaCardProps): JSX.Element {
  const { storage, toggleFav } = useFavStorage();
  const storageMecha = storage.get('mecha');

  const onToggleFav = () => {
    toggleFav('mecha', contract.tokenId.toString());
  };

  return (
    <div className="MechaCard nft-card">
      <div className="nft-card-header">
        <FavoriteButton
          isFavorite={storageMecha?.get(contract.tokenId.toString()) || false}
          onToggleFav={onToggleFav}
        />
      </div>
      <div className="nft-card-body">
        <p className="text-align-right">{dayjs(contract.ownedSince).fromNow()}</p>
        <NavLink className="nft-card-link" to={`/nfts/mecha/${contract.tokenId}`}>
          <h1>{contract.name}</h1>
          <div className="contract-content">
            <MechaContractAttributes contract={contract} />
          </div>
        </NavLink>
      </div>
    </div>
  );
}

export default MechaCard;
