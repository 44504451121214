import { useEffect, useState } from 'react';

import { useMyNFTs, useFavStorage, usePagination, useIsMounted, useDebounce } from 'hooks';
import { Paginator, MechaNFTFilters, MechaNFTList } from 'shared/components';
import {
  MECHA_DEFAULT_FILTERS,
  MechaNFTFiltersI,
  MechaNFTFilterType,
  MechaNFTOrderType,
  MECHA_DEFAULT_SORT,
} from 'shared/interfaces';
import { MechaNFTContract } from 'shared/models';

import loadingImage from 'assets/images/1480.gif';
import { MechaNFTSort } from 'shared/components/Mecha';
import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface MyMechasViewProps {
  currentAccount: string;
  isOwnAccount: boolean;
}

const MyMechasView = (props: MyMechasViewProps): JSX.Element => {
  const ITEMS_PER_PAGE = 12;
  const { currentAccount, isOwnAccount } = props;
  const isMounted = useIsMounted();
  const { storage } = useFavStorage();
  const [filters, setFilters] = useState<MechaNFTFiltersI>(MECHA_DEFAULT_FILTERS);
  const [sort, setSort] = useState<MechaNFTOrderType>(MECHA_DEFAULT_SORT);
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>();

  const {
    isLoadingMechas: isLoadingNFTs,
    reloadMechas: loadAllMyNFTs,
    mechas: myNfts,
  } = useMyNFTs(currentAccount, isOwnAccount);
  const [nfts, setNfts] = useState<Array<MechaNFTContract> | null>(myNfts);
  const [totalNFTs, setTotalNFTs] = useState<number>(myNfts.length);
  const { currentPage, nextPage, prevPage, totalPages, jumpToPage } = usePagination(
    ITEMS_PER_PAGE,
    totalNFTs,
  );

  useDebounce(
    () => {
      loadAllMyNFTs();
    },
    500,
    [loadAllMyNFTs, currentPage, filters, storage, currentAccount],
  );

  useEffect(() => {
    const getNFTAttributeValueFromFilterKey = (
      filterKey: MechaNFTFilterType,
      nft: MechaNFTContract,
    ): string | undefined => {
      if (filterKey === 'bodies') {
        return nft.body;
      }
      if (filterKey === 'colors') {
        return nft.color;
      }
      if (filterKey === 'massClasses') {
        return nft.massClassString;
      }
      if (filterKey === 'materials') {
        return nft.material;
      }
      if (filterKey === 'pilots') {
        return nft.pilot;
      }
      if (filterKey === 'powerSources') {
        return nft.powerSource;
      }

      return undefined;
    };

    const applyFilters = (nft: MechaNFTContract): boolean => {
      const storageMecha = storage.get('mecha');
      const filtersKeys = Object.keys(filters) as Array<MechaNFTFilterType>;
      return filtersKeys.every((filterKey: MechaNFTFilterType) => {
        const values = filters[filterKey];
        if (filterKey === 'favorite' && values) {
          return storageMecha?.has(nft.tokenId.toString());
        }

        if (!Array.isArray(values) || values.length === 0) {
          return true;
        }

        const value = getNFTAttributeValueFromFilterKey(filterKey, nft);
        if (value === undefined) {
          return true;
        }

        return values.includes(value);
      });
    };

    const applySort = (a: MechaNFTContract, b: MechaNFTContract): number => {
      return a.ownedSince > b.ownedSince ? -1 : 1;
    };

    const items: Array<MechaNFTContract> = myNfts.filter(applyFilters).sort(applySort);
    const initialIndex = ITEMS_PER_PAGE * (currentPage - 1);

    if (!isMounted) return;

    setTotalNFTs(items.length);
    setNfts(items.slice(initialIndex, initialIndex + ITEMS_PER_PAGE));
  }, [myNfts, currentPage, filters, storage, isMounted]);

  const openFilter = () => {
    setIsFiltersOpen(!isFiltersOpen);
  };

  return (
    <div className="MyMechasView">
      <div className="d-flex justify-content-center">
        {isLoadingNFTs === true && (
          <div className="mr-3">
            <img src={loadingImage} alt="loading" width={40} height={40} />
          </div>
        )}
        {totalPages && (
          <Paginator
            currentPage={currentPage}
            nextPage={nextPage}
            prevPage={prevPage}
            totalPages={totalPages}
            jumpToPage={jumpToPage}
          />
        )}
      </div>
      <div className="my-nfts">
        <div className="nfts-list mr-2">{nfts && <MechaNFTList nfts={nfts} />}</div>
        <div className="nfts-filters text-align-right">
          <FontAwesomeIcon icon={faFilter} onClick={openFilter} className="clickable icon-filter" />
          <div className={`filter-container text-align-left ${isFiltersOpen ? 'is-open' : ''}`}>
            {isFiltersOpen && (
              <span className="icon-close-filter">
                <FontAwesomeIcon icon={faTimes} onClick={openFilter} className="clickable" />
              </span>
            )}
            <h3 className="flex-1">Total NFTs: {totalNFTs}</h3>
            <MechaNFTSort sort={sort} setSort={setSort} />
            <MechaNFTFilters filters={filters} setFilters={setFilters} />
          </div>
        </div>
      </div>
      {totalPages && (
        <Paginator
          currentPage={currentPage}
          nextPage={nextPage}
          prevPage={prevPage}
          totalPages={totalPages}
          jumpToPage={jumpToPage}
        />
      )}
    </div>
  );
};

export default MyMechasView;
