import { useMetamask } from 'hooks';
import './ConnectDisconnectButton.scss';

const ConnectDisconnectButton = (): JSX.Element => {
  const { metaState, connect, disconnect } = useMetamask();
  const [currentAccount] = metaState.accounts;

  const truncateString = (
    fullString: string,
    strLen: number,
    separator: string = '...',
  ): string => {
    if (!fullString) return '';

    if (fullString.length <= strLen) return fullString;

    const sepLen = separator.length;
    const charsToShow = strLen - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return (
      fullString.substr(0, frontChars) +
      separator +
      fullString.substr(fullString.length - backChars)
    );
  };

  return (
    <div className="ConnectDisconnectButton">
      {currentAccount && (
        <>
          <span className="account">{truncateString(currentAccount, 15)}</span>
          <button
            type="button"
            className="btn btn-disconnect btn-primary"
            onClick={() => {
              disconnect();
            }}
          >
            Disconnect
          </button>
        </>
      )}
      {!currentAccount && (
        <button
          type="button"
          className="btn btn-connect btn-primary"
          onClick={() => {
            connect();
          }}
        >
          Connect
        </button>
      )}
    </div>
  );
};

export default ConnectDisconnectButton;
