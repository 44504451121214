import { useEffect, useState } from 'react';
import Select, { OptionsType } from 'react-select';
import './MultiSelect.scss';

interface MultiSelectProps {
  values: string[];
  options: string[];
  placeholder: string;
  // eslint-disable-next-line no-unused-vars
  applyFilters: (elements: string[]) => void;
}

interface MultiSelectOptionI {
  label: string;
  value: string;
}

export default function MultiSelect(props: MultiSelectProps): JSX.Element {
  // eslint-disable-next-line no-unused-vars
  const { values, options, placeholder, applyFilters } = props;
  const [value, setValue] = useState<Array<MultiSelectOptionI>>([]);
  const [multiSelectOptions, setMultiSelectOptions] = useState<Array<MultiSelectOptionI>>([]);

  const onFilterMultiSelectChanged = (event: OptionsType<MultiSelectOptionI>) => {
    applyFilters(event.map((element) => element.value));
    setValue(value);
  };

  const mapMultiSelectOptions = (optionsToMap: string[]) => {
    return optionsToMap.map((option) => {
      return {
        label: option,
        value: option,
      };
    });
  };

  // const customStyles = {
  //   menuList: () => ({
  //     overflowY: 'visible',
  //     posi
  //   }),
  // };

  useEffect(() => {
    setValue(mapMultiSelectOptions(values));
  }, [values]);

  useEffect(() => {
    setMultiSelectOptions(mapMultiSelectOptions(options));
  }, [options]);

  return (
    <Select
      value={value}
      placeholder={placeholder}
      className="MultiSelect"
      options={multiSelectOptions}
      isMulti
      onChange={onFilterMultiSelectChanged}
      // styles={{
      //   menu: () => ({
      //     overflowY: 'visible',
      //   }),
      //   // menuList: () => ({
      //   //   overflowY: 'visible',
      //   // }),
      // }}
    />
  );
}
