import { MechaNFTContract } from 'shared/models';

import './MechaContractAttributes.scss';

interface MechaContractAttributesProps {
  contract: MechaNFTContract;
}

const MechaContractAttributes = ({ contract }: MechaContractAttributesProps): JSX.Element => {
  return (
    <div className="MechaContractAttributes NFTContractAttributes">
      <p className="contract-attributes-item">
        <strong>Gen</strong>: {contract.generation}
      </p>
      <p className="contract-attributes-item">
        <strong>Mass</strong>: {contract.massString}
      </p>
      <p className="contract-attributes-item">
        <strong>Body</strong>: {contract.body}
      </p>
      <p className="contract-attributes-item">
        <strong>Color</strong>: {contract.color}
      </p>
      <p className="contract-attributes-item">
        <strong>Hull Material</strong>: {contract.material}
      </p>
      <p className="contract-attributes-item">
        <strong>Power Source</strong>: {contract.powerSource}
      </p>
      <p className="contract-attributes-item">
        <strong>Pilot</strong>: {contract.pilot}
      </p>
      <p className="contract-attributes-item">
        <strong>Weapon Slots</strong>: {contract.weaponSlots}
      </p>
      <p className="contract-attributes-item">
        <strong>Mod Slots</strong>: {contract.modSlots}
      </p>
    </div>
  );
};
export default MechaContractAttributes;
