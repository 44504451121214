import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './Tooltip.scss';

interface TooltipProps {
  text: string;
}

export default function Tooltip(props: TooltipProps): JSX.Element {
  const { text } = props;

  return (
    <span className="Tooltip">
      <FontAwesomeIcon className="tooltip-icon" icon={faInfoCircle} />
      <span className="tooltip-text">{text}</span>
    </span>
  );
}
