import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare, faDiscord, faTelegram } from '@fortawesome/free-brands-svg-icons';

import './Footer.scss';

export default function Footer(): JSX.Element {
  return (
    <div className="Footer">
      <div className="copyright">
        Copyright © 2021 <a href="https://mechaproject.io/">Mecha Project</a> All Rights Reserved
      </div>
      <div className="icons">
        <a className="clickable" href="https://twitter.com/themechaproject">
          <FontAwesomeIcon icon={faTwitterSquare} className="clickable" />
        </a>
        <a className="clickable" href="https://discord.gg/TXxEpqw59P">
          <FontAwesomeIcon icon={faDiscord} className="clickable" />
        </a>
        <a className="clickable" href="https://t.me/themechaproject">
          <FontAwesomeIcon icon={faTelegram} className="clickable" />
        </a>
      </div>
    </div>
  );
}
