import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';

import { useFavStorage } from 'hooks';
import dayjs from 'utils/dayjs';
import { ThreeDiceNFTContract } from 'shared/models';

import './ThreeDiceNFTContractViewer.scss';
import ThreeDiceContractAttributes from '../ThreeDiceContractAttributes/ThreeDiceContractAttributes';

interface ThreeDiceNFTContractViewerProps {
  contract: ThreeDiceNFTContract;
  contractAddress: string;
  // eslint-disable-next-line no-unused-vars
  contractClick?: (contract: ThreeDiceNFTContract) => any;
}

const ThreeDiceNFTContractViewer = ({
  contract,
  contractAddress,
  contractClick,
}: ThreeDiceNFTContractViewerProps): JSX.Element => {
  // const { value: isFav, setValue: setIsFav } = useLocalStorage(`three_${contract.tokenId}`, false);
  const { storage, toggleFav } = useFavStorage();
  const storageThree = storage.get('threeDice');
  const linkNFTrade = `https://app.nftrade.com/assets/avalanche/${contractAddress}/${contract.tokenId.toString()}`;
  const onContractClick = () => {
    if (contractClick) {
      contractClick(contract);
    }
  };

  const onToggleFav = () => {
    toggleFav('threeDice', contract.tokenId.toString());
  };

  return (
    <div className="ThreeDiceNFTContractViewer">
      <div className="text-align-right clickable icon-favorite">
        {storageThree?.get(contract.tokenId.toString()) ? (
          <FontAwesomeIcon icon={faHeartSolid} onClick={onToggleFav} />
        ) : (
          <FontAwesomeIcon icon={faHeart} onClick={onToggleFav} />
        )}
      </div>
      <div className={`${contractClick ? 'clickable' : ''}`} onClick={onContractClick}>
        <h1>{contract.name}</h1>
        <p className="text-align-right">{dayjs(contract.ownedSince).fromNow()}</p>
        <div className="contract-content">
          <img className="contract-image" alt="mecha" src={contract?.image} />
          <ThreeDiceContractAttributes contract={contract} />
        </div>
        <a className="nav-link" href={linkNFTrade}>
          View on NFTrade
        </a>
      </div>
    </div>
  );
};

ThreeDiceNFTContractViewer.defaultProps = {
  contractClick: undefined,
};

export default ThreeDiceNFTContractViewer;
