import { AVALANCHE_MAINNET_PARAMS, AVALANCHE_TESTNET_PARAMS } from 'context/metamaskStore';
import environment from 'config';
import { useMetamask } from 'hooks';

import './AvaxNetworkWarning.scss';

const AvaxNetworkWarning = (): JSX.Element => {
  const { switchEthereumChain } = useMetamask();

  return (
    <div className="AvaxNetworkWarning">
      <span>Please connect your wallet to view your NFTs</span>
      {environment.development && (
        <button
          type="button"
          className="btn btn-warning ml-3"
          onClick={() => {
            switchEthereumChain(AVALANCHE_TESTNET_PARAMS);
          }}
        >
          Switch to Avalanche Testnet C-Chain
        </button>
      )}
      <button
        type="button"
        className="btn btn-warning ml-3"
        onClick={() => {
          switchEthereumChain(AVALANCHE_MAINNET_PARAMS);
        }}
      >
        Switch to Avalanche Mainnet C-Chain
      </button>
    </div>
  );
};

export default AvaxNetworkWarning;
