import { KeyboardEvent } from 'react';

import './Paginator.scss';

interface PaginatorProps {
  currentPage: number;
  nextPage: CallableFunction;
  prevPage: CallableFunction;
  jumpToPage: CallableFunction;
  // eslint-disable-next-line react/require-default-props
  totalPages?: number;
}

export default function Paginator(props: PaginatorProps): JSX.Element {
  const { currentPage, nextPage, prevPage, totalPages, jumpToPage } = props;

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const target: HTMLInputElement = event.target as HTMLInputElement;
      jumpToPage(Number.parseInt(target.value, 10));
    }
  };

  return (
    <div className="Paginator">
      <button
        type="button"
        onClick={() => {
          jumpToPage(1);
        }}
        className="btn btn-primary"
      >
        {'<<'}
      </button>
      <button
        type="button"
        onClick={() => {
          prevPage();
        }}
        className="btn btn-primary"
      >
        {'<'}
      </button>
      <span>
        {currentPage} / {totalPages}
      </span>
      <button
        type="button"
        onClick={() => {
          nextPage();
        }}
        className="btn btn-primary"
      >
        {'>'}
      </button>
      {totalPages !== undefined && totalPages > 0 && (
        <button
          type="button"
          onClick={() => {
            jumpToPage(totalPages);
          }}
          className="btn btn-primary"
        >
          {'>>'}
        </button>
      )}
      <div>
        <span>Jump to Page:</span>
        <input type="number" name="" id="" defaultValue={currentPage} onKeyPress={handleKeyPress} />
      </div>
    </div>
  );
}
