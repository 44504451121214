export type ThreeDiceNFTFilterType = 'maxim' | 'minim' | 'favorite';

export type ThreeDiceNFTFiltersI = {
  maxim: number | null;
  minim: number | null;
  favorite: boolean;
};

export const THREE_DICE_DEFAULT_FILTERS: ThreeDiceNFTFiltersI = {
  maxim: null,
  minim: null,
  favorite: false,
};
