import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import './NotFoundView.scss';

const NotFoundView = (): JSX.Element => {
  return (
    <div className="NotFoundView">
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <NavLink to="/">
        <FontAwesomeIcon className="icon-go-back" icon={faChevronLeft} /> Go Home
      </NavLink>
    </div>
  );
};

export default NotFoundView;
