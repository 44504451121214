import { useStoredFavorites } from 'hooks';
import { useState, createContext, ReactChild, useEffect, useCallback } from 'react';

export type FavStorageMap = Map<string, Map<string, boolean>>;

export interface FavStorageState {
  storage: FavStorageMap;
  // eslint-disable-next-line no-unused-vars
  setStorage: (storage: FavStorageMap) => void;
}

const contextDefaultValues: FavStorageState = {
  storage: new Map<string, Map<string, boolean>>(),
  setStorage: () => {},
};

export const FavStorageContext = createContext<FavStorageState>(contextDefaultValues);

interface FavStorageContextProps {
  children: ReactChild;
}

export function FavStorageContextProvider({ children }: FavStorageContextProps) {
  const { storedFavorites, saveFavoritesToStorage } = useStoredFavorites();
  const [storage, setStorage] = useState<FavStorageMap>(storedFavorites);

  const storageToLocalStorage = useCallback(() => {
    saveFavoritesToStorage(storage);
  }, [storage, saveFavoritesToStorage]);

  useEffect(() => {
    storageToLocalStorage();
  }, [storageToLocalStorage]);

  return (
    <FavStorageContext.Provider value={{ storage, setStorage }}>
      {children}
    </FavStorageContext.Provider>
  );
}
