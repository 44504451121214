import { useEffect, useState } from 'react';

import { useMyNFTs, useFavStorage, useIsMounted, usePagination, useDebounce } from 'hooks';
import {
  ThreeDiceNFTList,
  Paginator,
  ThreeDiceNFTFilters,
  ThreeDiceNFTSort,
} from 'shared/components';
import {
  THREE_DICE_DEFAULT_FILTERS,
  ThreeDiceNFTFiltersI,
  THREE_DICE_DEFAULT_SORT,
  ThreeDiceNFTOrderType,
  ThreeDiceNFTOrder,
} from 'shared/interfaces';
import { ThreeDiceNFTContract } from 'shared/models';

import loadingImage from 'assets/images/1480.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';

interface My3DiceViewProps {
  currentAccount: string;
  isOwnAccount: boolean;
}

const My3DiceView = (props: My3DiceViewProps): JSX.Element => {
  const ITEMS_PER_PAGE = 12;
  const { currentAccount, isOwnAccount } = props;
  const { storage } = useFavStorage();
  const isMounted = useIsMounted();
  const [filters, setFilters] = useState<ThreeDiceNFTFiltersI>(THREE_DICE_DEFAULT_FILTERS);
  const [sort, setSort] = useState<ThreeDiceNFTOrderType>(THREE_DICE_DEFAULT_SORT);
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>();
  const {
    isLoadingThreeDices: isLoadingNFTs,
    reloadThreeDices: loadAllMyNFTs,
    threeDices: myNfts,
  } = useMyNFTs(currentAccount, isOwnAccount);
  const [nfts, setNfts] = useState<Array<ThreeDiceNFTContract> | null>(myNfts);
  const [totalNFTs, setTotalNFTs] = useState<number>(myNfts.length);
  const { currentPage, nextPage, prevPage, totalPages, jumpToPage } = usePagination(
    ITEMS_PER_PAGE,
    totalNFTs,
  );

  useDebounce(
    () => {
      loadAllMyNFTs();
    },
    500,
    [loadAllMyNFTs, currentPage, filters, storage, currentAccount],
  );

  useEffect(() => {
    const applyFilters = (nft: ThreeDiceNFTContract): boolean => {
      const storageMecha = storage.get('threeDice');
      const attributessKeys = Object.keys(nft.attributesThrowValue);
      const { maxim, minim, favorite } = filters;

      let isValid = true;

      if (favorite) {
        return storageMecha?.has(nft.tokenId.toString()) || false;
      }

      if (maxim !== null) {
        const valuesMaximFiltered = attributessKeys.find((attributeKey) => {
          const value = nft.attributesThrowValue[attributeKey];
          return value && value >= maxim;
        });
        isValid = isValid && valuesMaximFiltered !== undefined;
      }
      if (isValid && minim !== null) {
        const valuesMinimFiltered = attributessKeys.find((attributeKey) => {
          const value = nft.attributesThrowValue[attributeKey];
          return value && value <= minim;
        });
        isValid = isValid && valuesMinimFiltered !== undefined;
      }

      return isValid;
    };

    const applySort = (a: ThreeDiceNFTContract, b: ThreeDiceNFTContract): number => {
      if (sort === ThreeDiceNFTOrder.LOWEST_TOTAL_VALUE) {
        return a.totalRolls - b.totalRolls;
      }
      if (sort === ThreeDiceNFTOrder.HIGHEST_TOTAL_VALUE) {
        return b.totalRolls - a.totalRolls;
      }
      return a.ownedSince > b.ownedSince ? -1 : 1;
    };

    if (!isMounted) return;

    const initialIndex = ITEMS_PER_PAGE * (currentPage - 1);
    const items: Array<ThreeDiceNFTContract> = myNfts.filter(applyFilters).sort(applySort);
    setTotalNFTs(items.length);
    setNfts(items.slice(initialIndex, initialIndex + ITEMS_PER_PAGE));
  }, [currentPage, filters, myNfts, storage, sort, isMounted]);

  const openFilter = () => {
    setIsFiltersOpen(!isFiltersOpen);
  };

  return (
    <div className="My3DiceView">
      <div className="d-flex justify-content-center">
        {isLoadingNFTs === true && (
          <div className="mr-3">
            <img src={loadingImage} alt="loading" width={40} height={40} />
          </div>
        )}
        {totalPages && (
          <Paginator
            currentPage={currentPage}
            nextPage={nextPage}
            prevPage={prevPage}
            totalPages={totalPages}
            jumpToPage={jumpToPage}
          />
        )}
      </div>
      <div className="my-nfts">
        <div className="nfts-list mr-2">{nfts && <ThreeDiceNFTList nfts={nfts} />}</div>
        <div className="nfts-filters text-align-right">
          <FontAwesomeIcon icon={faFilter} onClick={openFilter} className="clickable icon-filter" />
          <div className={`filter-container text-align-left ${isFiltersOpen ? 'is-open' : ''}`}>
            {isFiltersOpen && (
              <span className="icon-close-filter">
                <FontAwesomeIcon icon={faTimes} onClick={openFilter} className="clickable" />
              </span>
            )}
            <h3 className="flex-1">Total NFTs: {totalNFTs}</h3>
            <ThreeDiceNFTSort sort={sort} setSort={setSort} />
            <ThreeDiceNFTFilters filters={filters} setFilters={setFilters} />
          </div>
        </div>
      </div>
      {totalPages && (
        <Paginator
          currentPage={currentPage}
          nextPage={nextPage}
          prevPage={prevPage}
          totalPages={totalPages}
          jumpToPage={jumpToPage}
        />
      )}
    </div>
  );
};

export default My3DiceView;
