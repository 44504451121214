import { useDebounce, useMetamask } from 'hooks';
import { ChangeEvent, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { AccountView } from 'views';
import './NFTAccountView.scss';

type TParams = { account: string };
interface NFTAccountViewProps extends RouteComponentProps<TParams> {}

const NFTAccountView = (props: NFTAccountViewProps): JSX.Element => {
  const { match } = props;
  const { url } = match;
  const isSearch = url.includes('account');
  const { account } = match.params;
  const { metaState } = useMetamask();
  const [cAccount] = metaState.accounts;
  const history = useHistory();
  const [currentAccount, setCurrentAccount] = useState<string>('');
  const [accountAddress, setAccountAddress] = useState<string>(account || '');
  const [isNFTsSearch, setIsNFTsSearch] = useState<boolean>(false);

  useDebounce(
    () => {
      history.push(`/nfts/account/${accountAddress}`);
      setCurrentAccount(accountAddress);
    },
    1000,
    [accountAddress],
  );

  useEffect(() => {
    if (isSearch) {
      setCurrentAccount(account || '');
      setAccountAddress(account || '');
    } else {
      setCurrentAccount(cAccount);
    }

    setIsNFTsSearch(isSearch);
  }, [account, cAccount, url, isSearch]);

  const changeCurrentAccount = (event: ChangeEvent<HTMLInputElement>) => {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    setAccountAddress(target.value);
  };

  return (
    <>
      {isNFTsSearch ? (
        <div className="account-info d-flex flex-row">
          <h3 className="flex-1">
            Account:
            <input type="text" value={accountAddress} onChange={changeCurrentAccount} />
          </h3>
          {/* <h3 className="flex-1">Provider: {environment.provider}</h3> */}
        </div>
      ) : null}

      {!currentAccount ? (
        <div className="no-account">No Account Selected</div>
      ) : (
        <AccountView currentAccount={currentAccount} isOwnAccount={!isSearch} />
      )}
    </>
  );
};

export default NFTAccountView;
