import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';

interface FavoriteButtonProps {
  isFavorite: boolean;
  onToggleFav: () => void;
}

const FavoriteButton = ({ isFavorite, onToggleFav }: FavoriteButtonProps): JSX.Element => {
  return (
    <div className="text-align-right icon-favorite">
      {isFavorite ? (
        <FontAwesomeIcon icon={faHeartSolid} onClick={onToggleFav} className="clickable" />
      ) : (
        <FontAwesomeIcon icon={faHeart} onClick={onToggleFav} className="clickable" />
      )}
    </div>
  );
};

export default FavoriteButton;
