import { useEffect, useCallback, useRef } from 'react';
import { Contract, Event as EthersEvent } from 'ethers';
import { formatFixed } from '@ethersproject/bignumber';
import { delay, lastValueFrom, of } from 'rxjs';

export interface TokenTransfer {
  tokenId: string;
  fromAddress: string;
  toAddress: string;
  timestamp: number;
}

export default function useAccountTransfers(contract: Contract, account: string) {
  const accountTransfers = useRef<Array<TokenTransfer> | null>(null);
  const isLoadingTransactions = useRef<boolean>(false);

  const loadTransactionData = async (etherEvent: EthersEvent): Promise<TokenTransfer | null> => {
    const { args } = etherEvent;

    if (!args) return null;

    const [fromAddress, toAddress, tokenId] = args;
    const block = await etherEvent.getBlock();
    const { timestamp } = block;

    return {
      tokenId: formatFixed(tokenId),
      fromAddress,
      toAddress,
      timestamp,
    } as TokenTransfer;
  };

  const loadTransfersToAddress = useCallback(async (): Promise<Array<TokenTransfer>> => {
    let events: Array<EthersEvent> = [];
    try {
      const transf = contract.filters.Transfer(null, account);
      events = await contract.queryFilter(transf);
    } catch (error) {
      // TODO: Handle error
    }
    const tData: Array<TokenTransfer | null> = await Promise.all(events.map(loadTransactionData));
    const transactionsData: Array<TokenTransfer> = [];

    // must use forEach since filter does not change array origin type and we dont want nulls on the array
    tData.forEach((t) => {
      if (t !== null) {
        transactionsData.push(t);
      }
    });

    return transactionsData;
  }, [contract, account]);

  const loadTransfersFromAddress = useCallback(async (): Promise<Array<TokenTransfer>> => {
    let events: Array<EthersEvent> = [];
    try {
      const transf = contract.filters.Transfer(account);
      events = await contract.queryFilter(transf);
    } catch (error) {
      // TODO: Handle error
    }
    const tData: Array<TokenTransfer | null> = await Promise.all(events.map(loadTransactionData));
    const transactionsData: Array<TokenTransfer> = [];

    // must use forEach since filter does not change array origin type and we dont want nulls on the array
    tData.forEach((t) => {
      if (t !== null) {
        transactionsData.push(t);
      }
    });

    return transactionsData;
  }, [contract, account]);

  const loadTransactions = useCallback(
    async (forceReload: boolean = false): Promise<Array<TokenTransfer>> => {
      if (isLoadingTransactions.current) {
        await lastValueFrom(of('').pipe(delay(500))); // sleep 500 ms
        return loadTransactions();
      }

      isLoadingTransactions.current = true;

      if (accountTransfers.current !== null && !forceReload) {
        isLoadingTransactions.current = false;
        return accountTransfers.current;
      }

      const transactionsTo = await loadTransfersToAddress();
      const transactionsFrom = await loadTransfersFromAddress();

      const allTransactions = transactionsTo.concat(transactionsFrom);
      const sortedTransactions = allTransactions.sort((t1, t2) => t1.timestamp - t2.timestamp);

      accountTransfers.current = sortedTransactions;
      isLoadingTransactions.current = false;

      return sortedTransactions;
    },
    [loadTransfersToAddress, loadTransfersFromAddress],
  );

  const getFinalOwnedTokenTransfers = useCallback(async (): Promise<Map<string, TokenTransfer>> => {
    const tokenTransferMap = new Map<string, TokenTransfer>();
    const accTransfers = await loadTransactions();

    if (accTransfers.length === 0) return tokenTransferMap;

    // for each token get its last transaction (since it is ordered by timestamp)
    accTransfers.forEach((accountTransfer) => {
      tokenTransferMap.set(accountTransfer.tokenId, accountTransfer);
    });

    // remove not owned NFTs
    Array.from(tokenTransferMap.entries()).forEach(([key, value]) => {
      if (value.toAddress.toLowerCase() !== account.toLowerCase()) {
        tokenTransferMap.delete(key);
      }
    });

    return tokenTransferMap;
  }, [loadTransactions, account]);

  const reloadTransactions = useCallback(async () => {
    return loadTransactions(true);
  }, [loadTransactions]);

  useEffect(() => {
    const init = async () => {
      const transactions = await loadTransactions();
      accountTransfers.current = transactions;
    };

    init();
  }, [loadTransactions]);

  return { accountTransfers, getFinalOwnedTokenTransfers, reloadTransactions };
}
