import { useLocalStorage } from './useStorage';

const ACCOUNT_KEY = 'MECHA_CHASIS_ACCOUNT';

export default function useStoredAccount() {
  const {
    value: account,
    setValue: storeAccount,
    remove: removeStoredAccount,
  } = useLocalStorage(ACCOUNT_KEY, null);

  return { account, storeAccount, removeStoredAccount };
}
