export type ThreeDiceNFTOrderType =
  | 'Highest total value'
  | 'Lowest total value'
  | 'Recently acquired';

export const ThreeDiceNFTOrder: {
  [key: string]: ThreeDiceNFTOrderType;
} = {
  HIGHEST_TOTAL_VALUE: 'Highest total value',
  LOWEST_TOTAL_VALUE: 'Lowest total value',
  MOST_RECENT: 'Recently acquired',
};

export const THREE_DICE_DEFAULT_SORT = ThreeDiceNFTOrder.MOST_RECENT;
