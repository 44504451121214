export const bodies = [
  'Biped',
  'Quadruped',
  'Spider',
  'Aerial',
  'Grey Goo',
  'Worm',
  'Wheeled',
  'Transformable',
];

export const materials = [
  'Titanium',
  'Beryllium',
  'Unobtainium',
  'Hyperalloy',
  'Duranium',
  'Tritanium',
  'Quadanium steel',
  'Vibranium',
  'Adamantium',
  'Chronosteel',
  'Dark steel',
  'Cyber metal',
  'Trinium',
  'Stealth ore',
  'Titalsteel',
  'Organic',
];

export const pilots = [
  'Penguin',
  'Rock',
  'Bored Ape',
  'Punk',
  'Alien',
  'Zombie',
  'Kitty',
  'Doge',
  'Maxi',
  'AI',
  'Yak',
  'Pangolin',
  'Joe',
  'Husky',
  'Spore',
  'Alice',
  'Rabbit',
  'Moon Goddess',
  'Shibx',
  'Baguette',
  'Elk',
];

export const colors = [
  'Ultramarine',
  'Turquoise',
  'Onyx',
  'Copper',
  'Viridian',
  'Indigo',
  'Gunmetal',
  'Gold',
  'Chartreuse',
  'Cherry',
  'Platinum',
  'Arctic Blue',
  'Amber',
  'Snow White',
  'Scarlet',
  'Magenta',
];

export const colorCodes = [
  '#120a8f',
  '#40e0d0',
  '#0f0f0f',
  '#b87333',
  '#40826d',
  '#4b0082',
  '#8D918D',
  '#ffd700',
  '#7fff00',
  '#de3163',
  '#e5e4e2',
  '#95d6dc',
  '#ffbf00',
  '#fffafa',
  '#Ff2400',
  '#Ff00ff',
];

export const powerSources = [
  'Zero Point Engine',
  'Fusion Reactor',
  'Antimatter Reactor',
  'Kugelblitz Drive',
  'Microverse Battery',
  'Hypermatter Reactor',
  'Dark Matter Reactor',
  'The Eye of Harmony',
  'Flux Capacitor',
];

export const massClasses = ['Light', 'Medium', 'Heavy', 'SuperHeavy'];
