import { MechaNFTFiltersI, MechaNFTFilterType, MECHA_DEFAULT_FILTERS } from 'shared/interfaces';
import * as FiltersMock from 'shared/mocks/filters.mock';
import './MechaNFTFilters.scss';
import { FavoriteFilters, MultiSelect } from 'shared/components';

interface MechaNFTFiltersProps {
  // eslint-disable-next-line no-unused-vars
  setFilters: (filters: MechaNFTFiltersI) => void;
  filters: MechaNFTFiltersI;
}

export default function MechaNFTFilters(props: MechaNFTFiltersProps): JSX.Element {
  const { filters, setFilters } = props;

  const clearFilters = () => {
    setFilters(MECHA_DEFAULT_FILTERS);
  };

  const onFilterMultiSelectChanged = (values: string[], filterKey: MechaNFTFilterType) => {
    setFilters({ ...filters, [filterKey]: values });
  };

  const onFilterFavoriteChanged = (value: boolean, filterKey: MechaNFTFilterType) => {
    setFilters({ ...filters, [filterKey]: value });
  };

  return (
    <div className="MechaNFTFilters w-100">
      <div className="d-flex align-items-center">
        <div className="flex-3">
          <h2>Filters</h2>
        </div>
        <div className="filter-clear flex-1">
          <button type="button" onClick={clearFilters} className="btn btn-primary">
            Clear
          </button>
        </div>
      </div>
      <div className="filter-controller checkbox-favorite">
        <FavoriteFilters
          favorite={filters.favorite}
          onChangeFav={(value) => {
            onFilterFavoriteChanged(value, 'favorite');
          }}
        />
      </div>
      <div className="filter-controller">
        <MultiSelect
          values={filters.bodies}
          options={FiltersMock.bodies}
          applyFilters={(values) => onFilterMultiSelectChanged(values, 'bodies')}
          placeholder="Bodies"
        />
      </div>
      <div className="filter-controller">
        <MultiSelect
          values={filters.materials}
          options={FiltersMock.materials}
          applyFilters={(values) => onFilterMultiSelectChanged(values, 'materials')}
          placeholder="Materials"
        />
      </div>
      <div className="filter-controller">
        <MultiSelect
          values={filters.pilots}
          options={FiltersMock.pilots}
          applyFilters={(values) => onFilterMultiSelectChanged(values, 'pilots')}
          placeholder="Pilots"
        />
      </div>
      <div className="filter-controller">
        <MultiSelect
          values={filters.powerSources}
          options={FiltersMock.powerSources}
          applyFilters={(values) => onFilterMultiSelectChanged(values, 'powerSources')}
          placeholder="Power Sources"
        />
      </div>
      <div className="filter-controller">
        <MultiSelect
          values={filters.massClasses}
          options={FiltersMock.massClasses}
          applyFilters={(values) => onFilterMultiSelectChanged(values, 'massClasses')}
          placeholder="Mass Classes"
        />
      </div>
    </div>
  );
}
