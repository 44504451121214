import { Contract } from 'ethers';
import { formatFixed } from '@ethersproject/bignumber';

import { NFTBase } from './NFTBase';

export type ThreeDiceTrailTypes =
  | 'Roll 1'
  | 'Roll 2'
  | 'Roll 3'
  | 'Roll 4'
  | 'Roll 5'
  | 'Roll 6'
  | 'Roll 7'
  | 'Roll 8';

export type ThreeDiceAttributesTypes =
  | 'roll1'
  | 'roll2'
  | 'roll3'
  | 'roll4'
  | 'roll5'
  | 'roll6'
  | 'roll7'
  | 'roll8';

interface ThreeDiceContractAttributesDTO {
  // eslint-disable-next-line camelcase
  display_type: string;
  // eslint-disable-next-line camelcase
  trait_type: ThreeDiceTrailTypes;
  value: number;
}

type RollType = {
  throw: string;
  value: number;
};
export interface ThreeDiceAttributes {
  roll1: RollType;
  roll2: RollType;
  roll3: RollType;
  roll4: RollType;
  roll5: RollType;
  roll6: RollType;
  roll7: RollType;
  roll8: RollType;
}
export interface ThreeDiceContractMetadataI {
  name: string;
  description: string;
  image: string;
  attributes: Array<ThreeDiceContractAttributesDTO>;
}

export class ThreeDiceNFTContract
  extends NFTBase<
    ThreeDiceContractAttributesDTO,
    ThreeDiceAttributes,
    ThreeDiceContractMetadataI
  >
  implements ThreeDiceAttributes
{
  roll1: RollType;
  roll2: RollType;
  roll3: RollType;
  roll4: RollType;
  roll5: RollType;
  roll6: RollType;
  roll7: RollType;
  roll8: RollType;
  totalRolls: number = 0;

  constructor(
    tokenId: string,
    data: string,
    owner: string,
    ownedSince?: string,
  ) {
    super(tokenId, data, owner, ownedSince);

    this.roll1 = this.attributes.roll1;
    this.roll2 = this.attributes.roll2;
    this.roll3 = this.attributes.roll3;
    this.roll4 = this.attributes.roll4;
    this.roll5 = this.attributes.roll5;
    this.roll6 = this.attributes.roll6;
    this.roll7 = this.attributes.roll7;
    this.roll8 = this.attributes.roll8;
    this.totalRolls = ThreeDiceNFTContract.calculateTotalThrows(
      this.attributes,
    );
  }

  get attributesThrowValue() {
    const attr: any = {};

    attr[this.roll1.throw] = this.roll1.value;
    attr[this.roll2.throw] = this.roll2.value;
    attr[this.roll3.throw] = this.roll3.value;
    attr[this.roll4.throw] = this.roll4.value;
    attr[this.roll5.throw] = this.roll5.value;
    attr[this.roll6.throw] = this.roll6.value;
    attr[this.roll7.throw] = this.roll7.value;
    attr[this.roll8.throw] = this.roll8.value;
    return attr;
  }

  // eslint-disable-next-line class-methods-use-this
  formatMetadata(metadata: string): ThreeDiceContractMetadataI {
    return JSON.parse(
      Buffer.from(metadata.substring(29), 'base64').toString(),
    ) as ThreeDiceContractMetadataI;
  }

  // eslint-disable-next-line class-methods-use-this
  formatAttributes(
    metadataAttributes: ThreeDiceContractAttributesDTO[],
  ): ThreeDiceAttributes {
    const threeDiceContractAttributes: ThreeDiceAttributes = {
      roll1: { throw: 'Roll 1', value: 0 },
      roll2: { throw: 'Roll 2', value: 0 },
      roll3: { throw: 'Roll 3', value: 0 },
      roll4: { throw: 'Roll 4', value: 0 },
      roll5: { throw: 'Roll 5', value: 0 },
      roll6: { throw: 'Roll 6', value: 0 },
      roll7: { throw: 'Roll 7', value: 0 },
      roll8: { throw: 'Roll 8', value: 0 },
    };
    Object.entries(threeDiceContractAttributes).forEach(([key, rollValue]) => {
      threeDiceContractAttributes[key as ThreeDiceAttributesTypes].value =
        metadataAttributes.find((a) => a.trait_type === rollValue.throw)
          ?.value || 0;
    });
    return threeDiceContractAttributes;
  }

  static calculateTotalThrows(attributes: ThreeDiceAttributes): number {
    return Object.values(attributes)
      .map((attr) => attr.value)
      .reduce((prev, current) => {
        return prev + current;
      }, 0);
  }

  static async createInstance(
    tokenId: string,
    ethContract: Contract,
    ownedSince?: string,
  ): Promise<ThreeDiceNFTContract> {
    const data = await ethContract.tokenURI(tokenId);
    const ownerId = await ethContract.ownerOf(tokenId);
    return new ThreeDiceNFTContract(tokenId, data, ownerId, ownedSince);
  }

  static async createInstanceAllData(
    tokenId: string,
    ethContract: Contract,
  ): Promise<ThreeDiceNFTContract> {
    const data = await ethContract.tokenURI(tokenId);
    const ownerId = await ethContract.ownerOf(tokenId);

    // eslint-disable-next-line no-unused-vars
    const attributes = {
      roll1: await this.createRollType(tokenId, ethContract, 0),
      roll2: await this.createRollType(tokenId, ethContract, 1),
      roll3: await this.createRollType(tokenId, ethContract, 2),
      roll4: await this.createRollType(tokenId, ethContract, 3),
      roll5: await this.createRollType(tokenId, ethContract, 4),
      roll6: await this.createRollType(tokenId, ethContract, 5),
      roll7: await this.createRollType(tokenId, ethContract, 6),
      roll8: await this.createRollType(tokenId, ethContract, 7),
    };

    return new ThreeDiceNFTContract(tokenId, data, ownerId);
  }

  private static async createRollType(
    tokenId: string,
    ethContract: Contract,
    rollNumber: number,
  ): Promise<RollType> {
    const rollString = await ethContract.getRollString(tokenId, rollNumber);
    const rollValue = await ethContract.getRollValue(tokenId, rollNumber);
    return {
      throw: rollString,
      value: Number.parseInt(formatFixed(rollValue), 10),
    };
  }
}
